.pending {
	width: 100%;
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4rem;
	&-image {
		max-width: 17rem;
	}
	&-main {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.5rem;
		@media screen and (max-width: 768px) {
			gap: 1rem;
			width: 50%;
			max-width: 32rem;
			min-width: 20rem;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;
		&-header {
			font-size: 1.5rem;
			font-size: 500;
			background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
		&-text {
			text-align: center;
			color: #808080;
			max-width: 70%;
		}
	}
	&-actions {
		max-width: 32rem;
		min-width: 20rem;
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}
