.home {
	padding-block: 1.625rem 3.75rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	&-ad {
		&-image {
			width: 100%;
			height: 100%;
			object-fit: fill;
			border-radius: 2rem;
			height: 25rem;
			@media screen and (max-width: 769px) {
				height: auto;
				aspect-ratio: 1/0.3;
			}
			@media screen and (max-width: 500px) {
				aspect-ratio: 1/0.35;
			}
		}
	}
}
