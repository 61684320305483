.card {
	display: flex;
	gap: 1rem;
	align-items: center;
	background-color: #fff;
	padding: 0.5rem;
	padding-inline-end: 2rem;
	border-radius: 1rem;
	cursor: pointer;
	&-delete {
		position: absolute;
		inset-block-start: 0.75rem;
		inset-inline-end: 0.75rem;
		&-icon {
			width: 1rem;
		}
	}
	&-read {
		position: relative;
	}
	&-unread {
		position: relative;
		background-color: #f7a92010;
		.card-delete {
			&::after {
				position: relative;
				content: '';
				display: block;
				width: 0.5rem;
				aspect-ratio: 1;
				background-color: #f7a920;
				border-radius: 50%;
				inset-inline-end: -0.25rem;
				// inset-block-start: 1rem;
			}
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	&-title {
		font-size: 1rem;
		font-weight: 500;
	}
	&-date {
		font-size: 0.875rem;
		font-weight: 400;
		color: #bfbfbf;
	}
}
