.big-win {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	&-header {
		font-weight: 600;
		font-size: 2.5rem;
		color: #808080;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		gap: 0.25rem;
		align-self: start;
		padding-block: 0.5rem;
		padding-inline: 0.5rem;
		background: linear-gradient(to left, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		// border-block-end: 3px solid #f59b2a;
		span {
			background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
	}
}
.card {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: stretch;
	gap: 1rem;
	height: 16rem;
	background-color: #f7a920;
	color: #fff;
	border-radius: 1rem;
	padding-inline: 1rem;
	padding-block: 2rem;
	background-image: url(../../assets/images/big-win-confetti.png);
	@media screen and (max-width: 1024px) {
		gap: 1rem;
	}
	&-border {
		cursor: pointer;
		padding: 0.25rem;
		background-image: linear-gradient(283.73deg, #ffd16a 17.79%, #f5a71f 30.3%, #dc1586 95.99%);
		border-radius: 1rem;
	}
	&-image {
		max-width: 100%;
		height: 100%;
		max-height: 100%;
		object-fit: contain;
		aspect-ratio: 1;
		&-container {
			width: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			max-height: 100%;
			background-color: #fff;
			padding: 0.5rem 1rem;
			border-radius: 2rem;
			// aspect-ratio: 1;
			height: 98%;
			min-height: 98%;
			align-self: center;
		}
	}
	&-content {
		// width: 60%;
		display: flex;
		flex-grow: 1;
		gap: 0.25rem;
		flex-direction: column;
		// gap: 1.5rem;
		justify-content: space-between;
		&-name {
			font-size: 1.25rem;
		}
		&-congrats {
			font-size: 2rem;
			color: #dc1586;
			font-weight: 700;
			text-transform: uppercase;
		}
		&-user {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: #fff;
		}
		&-time {
			display: flex;
			flex-direction: column;
		}
		&-details {
			display: flex;
			gap: 1rem;
		}
	}
}

.user {
	&-image {
		width: 3rem;
		height: 3rem;
		aspect-ratio: 1;
		object-fit: cover;
		border-radius: 50%;
	}
	&-name {
		font-size: 1rem;
		text-transform: capitalize;
	}
}

.time {
	&-text {
		font-size: 0.75rem;
	}
	&-date {
		font-weight: 500;
		// color: #de1a87;
	}
}

.details {
	&-wheel {
		font-size: 0.75rem;
		display: flex;
		flex-direction: column;
		span {
			font-size: 1rem;
			font-weight: 500;
		}
	}
	&-ticket {
		font-size: 1rem;
		display: flex;
		gap: 0.5rem;
		span {
			font-size: 1rem;
			font-weight: 500;
		}
	}
}

.product-details-content-wheel-text-sec {
	position: absolute;
	inset-block-end: 0.5rem;
	font-weight: 400;
	color: #fff;
	font-size: 1rem;
	background: radial-gradient(46.62% 48.85% at 49.85% 51.31%, #e23373 50%, #f7ac38 100%);
	padding: 0.5rem 1.5rem;
	align-self: flex-start;
	border-radius: 3rem;
	width: fit-content;
	text-transform: uppercase;
	span {
		font-weight: 700;
	}
}
