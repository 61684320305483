@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --padding-x: 4rem;
  font-size: 16px;
  background-color: #fff;
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
}

a {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;
}

html {
  overflow-x: hidden;
}

html:lang(ar) *{
  direction: rtl !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader-container-small {
  width: 100%;
  height: 70vh;
  display: grid;
  place-content: center
}
.loader-container {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center
}

.loader {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 0.5rem solid #F7A920;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

@media screen and (max-width: 3840px) {
  :root {
    font-size: 20px;
  }
}

@media screen and (max-width: 2200px) {
  :root {
    font-size: 18px;
  }
}

@media screen and (max-width: 1920px) {
  :root {
    font-size: 16px;
  }
}

@media screen and (max-width: 1400px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    --padding-x: 2rem;
  }
}

@media screen and (max-width: 500px) {
  :root {
    --padding-x: 1rem;
  }
}
