.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-header {
		display: flex;
		color: #000000;
		font-weight: 500;
		font-size: 1.5rem;
		justify-content: center;
		align-items: center;
	}
	&-close {
		width: 1.5rem;
		aspect-ratio: 1;
		position: absolute;
		inset-block-start: 1.5rem;
		inset-inline-end: 1rem;
		transform: translateY(-50%);
		cursor: pointer;
	}
	&-container {
		background-color: #fff;
		border-radius: 1rem;
		padding: 2rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		position: relative;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
		button {
			text-transform: capitalize;
			&:last-child {
				background-color: transparent;
				color: #808080;
			}
		}
	}
	&-text {
		font-size: 1.25rem;
		color: #3c3c43;
		margin-block: 1.5rem 2rem;
	}
}
