.progress {
	display: flex;
	justify-content: space-between;
	&-step {
		position: relative;
		width: 5%;
		aspect-ratio: 1;
		border-radius: 50%;
		border: 3px solid #9ca3af;
		background-color: #fff;
		@media screen and (max-width: 768px) {
			width: 6%;
		}
		@media screen and (max-width: 600px) {
			width: 7%;
		}
		@media screen and (max-width: 500px) {
			width: 8%;
		}
		&::before {
			content: '';
			position: absolute;
			width: 40%;
			aspect-ratio: 1;
			background-color: #d1d5db;
			border-radius: 50%;
			inset-block-start: 50%;
			inset-inline-start: 50%;
			transform: translate(-50%, -50%);
		}
		&:lang(ar) {
			&::before {
				transform: translate(50%, -50%);
			}
		}
		&:not(:last-child):after {
			content: '';
			width: 200%;
			height: 3px;
			background-color: #9ca3af;
			position: relative;
			display: block;
			inset-block-start: 50%;
			inset-inline-start: 105%;
			transform: translateY(-50%);
			z-index: 0;
			@media screen and (max-width: 600px) {
				width: 150%;
			}
		}
		&-active {
			border-color: #f7a920;
			z-index: 0;
			&::before {
				background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
			}
		}
		&-completed {
			position: relative;
			aspect-ratio: 1;
			border: none;
			border-radius: 50%;
			background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
			&::before {
				content: none;
			}
			&:not(:last-child):after {
				inset-inline-start: 100%;
				width: 115%;
				background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
			}
			p {
				inset-block-start: 135% !important;
			}
		}
		p {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			inset-block-start: 150%;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			&:lang(ar) {
				transform: translateX(50%);
			}
			font-weight: 700;
			span {
				font-weight: 400;
			}
		}
	}
}
