.discount {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	@media screen and (max-width: 1200px) {
		margin-block-start: 2rem;
	}
	&-empty {
		width: 50%;
		aspect-ratio: 1;
		object-fit: cover;
		align-self: center;
	}
	&-header {
		font-weight: 700;
		font-size: 3.25rem;
		background: linear-gradient(to left, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		text-align: center;
		@media screen and (max-width: 650px) {
			font-size: 2.25rem;
		}
		@media screen and (max-width: 450px) {
			font-size: 2rem;
		}
		&-gray {
			font-size: 2.5rem;
			font-weight: 500;
			color: #808080;
			@media screen and (max-width: 650px) {
				font-size: 2rem;
			}
			@media screen and (max-width: 450px) {
				font-size: 1.5rem;
			}
		}
		&-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@media screen and (max-width: 1050px) {
				img {
					height: 10rem;
				}
			}
			@media screen and (max-width: 650px) {
				img {
					height: 7rem;
				}
			}
			@media screen and (max-width: 450px) {
				img {
					height: 5rem;
				}
			}
		}
	}
	&-container {
		width: 100%;
		display: flex;
		gap: 0.5rem;
		flex-wrap: wrap;
	}
	&-product {
		width: calc(calc(100% / 3) - calc(0.5rem * 2 / 3));
	}
	&-transactions {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-header {
			font-weight: 500;
			font-size: 1.5rem;
			color: #3c3c43;
		}
		&-list {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
	}
}

.level {
	display: flex;
	flex-direction: column;
	&-header {
		font-size: 1.5rem;
		font-weight: 500;
		text-transform: capitalize;
	}
	&-progress {
		margin-block: 2rem 3rem;
		width: 100%;
		background-color: #d9d9d9;
		height: 1rem;
		border-radius: 1rem;
	}
	&-levels {
		display: flex;
		justify-content: space-between;
		font-size: 1.25rem;
		font-weight: 500;
		color: #000000;
	}
	&-content {
		margin-block-start: 2rem;
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
	}
	&-text {
		font-size: 0.75rem;
	}
	&-redeem {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		font-size: 1rem;
		padding: 1rem 1rem;
		border-radius: 1.5rem;
		background: linear-gradient(180deg, #f7a821 0%, #dd1a82 100%);
		button {
			all: unset;
			background-color: #fff;
			cursor: pointer;
			color: #de1a87;
			border: 2px solid #de1a87;
			border-radius: 5rem;
			padding: 1rem 2.5rem;
			font-size: 1.5rem;
			font-weight: 500;
			text-transform: uppercase;
		}
		div {
			display: flex;
			gap: 1rem;
			align-items: center;
		}
		&-store {
			width: 5rem;
			height: 5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			border-radius: 100%;
			aspect-ratio: 1;
			img {
				width: 100%;
				height: 100%;
				aspect-ratio: 1;
				// background-color: #fff;
				padding: 0.75rem;
			}
		}
	}
}

.progress-bar {
	border-radius: 1rem;
	width: 0;
	position: relative;
	background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	height: 1rem;
	&-indicator {
		position: absolute;
		inset-inline-end: 0;
		inset-block-start: 0;
		transform: translate(55%, -25%);
		&:lang(ar) {
			transform: translate(-55%, -25%);
		}
		&::after {
			position: absolute;
			display: grid;
			content: attr(aria-level);
			color: #fff;
			inset: 0;
			place-content: center;
			transform: translateY(-11%);
			font-size: 1.125rem;
		}
	}
}
