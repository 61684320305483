.swipe {
	--track-height: 4rem;
	--thumb-height: 5rem;
	-webkit-appearance: none;
	appearance: none;
	background: transparent;
	cursor: pointer;
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 75%;
	}
	@media screen and (max-width: 500px) {
		width: 100%;
	}
	/***** Track Styles *****/
	/***** Chrome, Safari, Opera, and Edge Chromium *****/
	&::-webkit-slider-runnable-track {
		border: 2px solid #f7a920;
		background: #fff;
		height: var(--track-height);
		border-radius: 4rem;
	}

	/******** Firefox ********/
	&::-moz-range-track {
		border: 2px solid #f7a920;
		background: #fff;
		height: var(--track-height);
		border-radius: 4rem;
	}

	/***** Thumb Styles *****/
	/***** Chrome, Safari, Opera, and Edge Chromium *****/
	&::-webkit-slider-thumb {
		-webkit-appearance: none; /* Override default look */
		appearance: none;
		margin-top: calc(calc(var(--track-height) / 2) - calc(var(--thumb-height) / 2)); /* Centers thumb on the track */
		background-color: #f7a920;
		height: var(--thumb-height);
		width: var(--thumb-height);
		border-radius: 50%;
	}

	/***** Firefox *****/
	&::-moz-range-thumb {
		border: none; /*Removes extra border that FF applies*/
		border-radius: 50%; /*Removes default border-radius that FF applies*/
		background-color: #f7a920;
		height: var(--thumb-height);
		width: var(--thumb-height);
	}
	&:disabled {
		opacity: 0.5;
	}
}
