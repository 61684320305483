.tab {
	width: 65%;
	padding: 2rem;
	align-self: stretch;
	background-color: #fff;
	border-radius: 2rem;
	box-shadow: 0 0 10px 2px #0002;
	position: relative;
	&-spaceless {
		padding: 0;
		padding-block: 0 4rem;
		border-radius: 4.75rem;
	}
	&-menu {
		display: none;
		position: absolute;
		inset-inline-start: 1rem;
		inset-block-start: 1rem;
		width: 3rem;
		background-color: #0001;
		border-radius: 0.25rem;
		padding: 0.5rem;
		cursor: pointer;
		@media screen and (max-width: 1200px) {
			display: block;
		}
	}
	@media screen and (max-width: 1200px) {
		width: 90%;
		padding-inline: calc(3 * var(--padding-x));
		margin-block: 2rem;
		box-shadow: none;
	}
	@media screen and (max-width: 1000px) {
		width: 100%;
		padding-inline: calc(2 * var(--padding-x));
		margin-inline: var(--padding-x);
	}
	@media screen and (max-width: 768px) {
		padding-inline: calc(2 * var(--padding-x));
		margin-inline: 0;
	}
	@media screen and (max-width: 500px) {
		padding-inline: var(--padding-x);
	}
}

.open {
	align-self: flex-start;
}

.no-padding {
	@media screen and (max-width: 1200px) {
		padding-inline: 0;
	}
}
