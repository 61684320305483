.image-viewer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	&-thumbs {
		width: 100%;
		display: flex;
		gap: 1rem;
	}
}

.thumb {
	width: calc(100% - 3rem / 4);
	max-width: 5.5rem;
	aspect-ratio: 1;
	border: 1px solid #f2f2f2;
	border-radius: 0.5rem;
	object-fit: contain;
}

.thumbnail {
	max-width: calc(5.5rem * 4 + 3rem);
	max-height: 50vh;
	overflow: hidden;
	&-image {
		border-radius: 0.5rem;
		border: 1px solid #f2f2f2;
		width: 100%;
		height: 100%;
		max-height: 50vh;
		object-fit: contain;
	}
}
