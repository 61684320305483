.wheel {
	position: relative;
	width: 90%;
	aspect-ratio: 1;
	border-radius: 100%;
	padding: 5px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #df3f83;
	max-width: min(40rem, 70vh);
	max-height: min(40rem, 70vh);
	z-index: 0;
	@media screen and (max-width: 768px) {
		width: 100%;
		max-width: 40rem;
		max-height: 40rem;
		overflow: hidden;
	}
	&:lang(ar) {
		direction: ltr !important;
		& * {
			direction: ltr !important;
		}
	}
	&-center {
		width: 5rem;
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: 50%;
		transform: translateY(-50%) translateX(-50%);
		&-animation {
			animation: flip 2s infinite;
		}
	}
	&-indicator {
		width: 2rem;
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
}

@keyframes flip {
	0% {
		transform: translateY(-50%) translateX(-50%) rotateY(0deg);
	}
	100% {
		transform: translateY(-50%) translateX(-50%) rotateY(360deg);
	}
}
