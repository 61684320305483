.discount {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem;
	@media screen and (max-width: 1200px) {
		margin-block-start: 4rem;
		gap: 1;
	}
	&-container {
		width: 100%;
		display: flex;
		gap: 2rem 0.5rem;
		flex-wrap: wrap;
		@media screen and (max-width: 1200px) {
			// padding: 2rem;
		}
		@media screen and (max-width: 450px) {
			// width: 90%;
			margin-inline: auto;
		}
	}
	&-product {
		width: calc(calc(100% / 3) - calc(0.5rem * 2 / 3));
		@media screen and (max-width: 1200px) {
			width: calc(calc(100% / 2) - calc(1rem / 2));
		}
		@media screen and (max-width: 550px) {
			width: 100%;
		}
	}
}

.faded {
	opacity: 0.5;
}
