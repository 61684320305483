.my-wheels {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	&-header {
		font-weight: 500;
		font-size: 1.5rem;
		color: #3c3c43;
	}
	&-container {
		width: 100%;
		display: flex;
		gap: 2rem 0.5rem;
		flex-wrap: wrap;
		@media screen and (max-width: 1200px) {
			padding: 2rem;
		}
		@media screen and (max-width: 450px) {
			width: 90%;
			margin-inline: auto;
		}
	}
	&-product {
		width: calc(calc(100% / 3) - calc(0.5rem * 2 / 3));
		@media screen and (max-width: 650px) {
			width: calc(calc(100% / 2) - calc(1rem / 2));
		}
		@media screen and (max-width: 450px) {
			width: 100%;
		}
	}
	&-empty {
		width: 50%;
		margin-inline: auto;
		justify-self: center;
		align-self: center;
		margin-block-start: 8rem;
	}
}
