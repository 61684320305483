.top-up {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	&-header {
		font-weight: 500;
		font-size: 4.75rem;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		text-align: center;
		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			img {
				&:nth-of-type(2) {
					height: 9rem;
				}
			}
			@media screen and (max-width: 1000px) {
				img {
					height: 4rem;
					&:nth-of-type(2) {
						height: 6rem;
					}
				}
				gap: 1rem;
			}
			@media screen and (max-width: 400px) {
				gap: 0.5rem;
			}
		}
		@media screen and (max-width: 1250px) {
			font-size: 4rem;
		}
		@media screen and (max-width: 1050px) {
			font-size: 3.75rem;
		}
		@media screen and (max-width: 768px) {
			font-size: 3rem;
		}
		@media screen and (max-width: 450px) {
			font-size: 2.5rem;
		}
	}
	&-hint {
		font-size: 1rem;
		font-weight: 400;
		color: #808080;
		text-align: center;
	}
	&-text {
		font-size: 1.25rem;
		font-weight: 500;
		color: #808080;
		text-transform: capitalize;
		@media screen and (max-width: 1200px) {
			margin-block-start: 2rem;
		}
	}
	&-custom {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-input {
			border: 1px solid #bfbfbf;
			height: 3rem;
			font-size: 1.25rem;
			border-radius: 1rem;
			padding-inline: 1rem;
			text-align: center;
			&::placeholder {
				text-align: center;
			}
			&:focus,
			&:focus-visible,
			&:focus-within {
				outline: none;
			}
		}
	}
	&-pay {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-text {
			font-size: 1.25rem;
			font-weight: 500;
			color: #808080;
			text-transform: capitalize;
		}
	}
	&-amount {
		display: flex;
		flex-wrap: wrap;
		gap: 4.25rem;
		padding-inline: 4rem;
		justify-content: center;
		@media screen and (max-width: 768px) {
			padding-inline: 0;
		}
		@media screen and (max-width: 550px) {
			gap: 2rem;
		}
		@media screen and (max-width: 450px) {
			gap: 0.5rem;
		}
		&-button {
			all: unset;
			cursor: pointer;
			border: 1px solid #bfbfbf;
			font-size: 2rem;
			font-weight: 700;
			color: #808080;
			padding-block: 1rem;
			text-align: center;
			width: calc(calc(100% / 3) - calc(0.75rem * 2 / 3));
			border-radius: 1.25rem;
			&-selected {
				border-color: #dd1a82;
				color: #dd1a82;
			}
		}
	}
}
