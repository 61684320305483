.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-container {
		background-color: #fff;
		border-radius: 1rem;
		padding-block-end: 3rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		align-items: center;
		opacity: 0;
		animation: showModal 3s ease-in-out forwards;
		max-width: 35rem;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		padding-inline: 1.25rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		button {
			width: auto;
			margin-top: 2rem;
			padding-block: 0.75rem;
			padding-inline: 9rem;
			font-size: 1rem;
			font-weight: 500;
		}
	}
	&-text {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		&-secondary {
			font-size: 1rem;
			color: #808080;
		}
	}
	&-image {
		max-height: 29rem;
		max-width: 100%;
	}
}

@keyframes showModal {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
