.form {
	padding-inline: 6.5rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	@media screen and (max-width: 1200px) {
		padding-inline: 4rem;
	}
	@media screen and (max-width: 1024px) {
		padding-inline: 2rem;
	}
	@media screen and (max-width: 769px) {
		padding-inline: 0;
	}
	&-logo {
		width: 50%;
		cursor: pointer;
	}
	&-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 0.5rem;
		&-title {
			font-size: 1rem;
			font-weight: 500;
			color: #000;
		}
		&-body {
			font-size: 0.75rem;
			font-weight: 400;
			line-height: 1.25;
			color: #808080;
		}
	}
	&-input-container {
		margin-block-start: 0.5rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	&-signup-text {
		align-self: center;
		color: #808080;
		font-size: 1rem;
		text-align: center;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		a {
			font-size: 1rem;
			text-decoration: none;
			color: #de1a87;
			font-weight: 500;
		}
	}
	&-actions {
		margin-block-start: 1.5rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		.language-switcher-container {
			display: flex;
			align-self: center;
		}
	}
	.forget-password {
		margin-block-start: 0.25rem;
		font-size: 0.875rem;
		font-weight: 500;
		text-decoration: none;
		color: #808080;
		align-self: flex-start;
	}
}
