.help {
	display: flex;
	flex-direction: column;
	align-items: center;
	&-header {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		padding-block: 3rem;
		background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
	&-main {
		width: 90%;
		margin-block-start: -2.5rem;
		padding-inline: var(--padding-x);
		background-color: #fff;
		padding: 3rem;
		padding-block-end: 7rem;
		border-start-start-radius: 1rem;
		border-start-end-radius: 1rem;
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	&-email {
		display: flex;
		gap: 1rem;
		align-items: flex-start;
		&-text {
			display: flex;
			flex-direction: column;
			gap: 0.25rem;
			font-size: 0.75rem;
			a {
				display: flex;
				flex-direction: column;
				gap: 0.25rem;
			}
		}
		&-email {
			font-size: 0.875rem;
			font-weight: 500;
			color: #3c3c43;
		}
	}
	&-contact {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		&-header {
			color: #1a191a;
			text-transform: capitalize;
		}
		&-label {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			text-transform: capitalize;
			font-size: 0.875rem;
			color: #3c3c43;
		}
	}
}
