.product-details {
	padding-block: 4rem;
	display: flex;
	gap: 4.375rem;
	overflow-x: hidden;
	@media screen and (max-width: 1024px) {
		gap: 2rem;
	}
	@media screen and (max-width: 769px) {
		gap: 1rem;
		flex-direction: column;
	}
	&-image-viewer {
		width: 34%;
		flex-grow: 1;
		@media screen and (max-width: 769px) {
			width: auto;
			align-self: center;
		}
	}
	&-content {
		max-width: calc(100% - 25rem);
		width: calc(66% - 4.375rem);
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		@media screen and (max-width: 769px) {
			max-width: 100%;
			width: calc(100% - 4.375rem);
			align-self: center;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
		}
		&-title {
			font-size: 2rem;
			font-weight: 500;
		}
		&-rating {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			&-text {
				padding-inline-start: 0.5rem;
				padding-block: 0.125rem;
				border-inline-start: 1px solid #9f9f9f;
				color: #9f9f9f;
				font-size: 0.875rem;
			}
		}
		&-won {
			align-self: start;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: #3c3c43;
			padding: 0.5rem 1rem;
			background-color: #f7a9203d;
			border-radius: 1rem;
			&-text {
				display: flex;
				align-items: center;
				gap: 0.25rem;
				font-size: 1.5;
			}
			&-number {
				font-weight: 500;
				font-size: 1.25;
			}
		}
		&-wheel {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&-text {
				font-size: 0.875rem;
				display: flex;
				align-items: center;
				gap: 0.5rem;
				font-weight: 500;
				color: #808080;
				text-transform: uppercase;
				letter-spacing: 1px;
				&-sec {
					font-weight: 400;
					color: #fff;
					font-size: 1rem;
					background: radial-gradient(46.62% 48.85% at 49.85% 51.31%, #e23373 50%, #f7ac38 100%);
					padding: 0.5rem 1.5rem;
					align-self: flex-start;
					border-radius: 3rem;
					width: fit-content;
					text-transform: uppercase;
					span {
						font-weight: 700;
					}
					@media screen and (max-width: 500px) {
						padding: 0.25rem 0.75rem;
						font-size: 0.875rem;
					}
				}
			}
			&-number {
				font-weight: 500;
				font-size: 0.875rem;
				color: #808080;
				padding: 0.125rem 0.75rem;
				background-color: #d9d9d9;
				border-radius: 1rem;
			}
		}
		&-description {
			max-width: 26.5rem;
			font-size: 0.875rem;
			line-height: 1.5;
			margin-top: 1.5625rem;
		}
		&-tickets {
			display: flex;
			gap: 0.5rem;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img {
				width: 7rem;
				&:not(:nth-child(1)) {
					opacity: 0.5;
				}
			}
			&-text {
				display: flex;
				gap: 0.25rem;
				align-items: baseline;
				color: #808080;
				font-weight: 500;
				align-self: start;
			}
			&-number {
				font-size: 1.25rem;
				font-weight: 500;
				background: -webkit-linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}
	&-action {
		all: unset;
		cursor: pointer;
		background-color: #f7a821;
		color: #fff;
		display: flex;
		justify-content: center;
		font-size: 1.25rem;
		padding-block: 0.75rem;
		border-radius: 1rem;
		margin-top: 0.5rem;
		&:disabled {
			background-color: #e4e4e4;
			cursor: not-allowed;
		}
	}
}

.slider {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	color: #808080;
	&-title {
		font-size: 0.875rem;
		font-weight: 500;
	}
	&-description {
		font-size: 0.75rem;
	}
}
