@property --progress {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 0%;
}

.progressbar {
	--bar-width: 1.25rem;

	width: 100%;
	height: 100%;
	aspect-ratio: 1 / 1;
	background: conic-gradient(#f7a821 0%, #dd1a82 var(--progress, 0), #ccc 0%);
	border-radius: 50%;
	display: grid;
	place-items: center;
	transition: --progress 500ms linear;

	&::after {
		content: attr(aria-valuenow) '%';
		display: grid;
		place-items: center;
		font-size: 2rem;
		border-radius: inherit;
		width: calc(100% - var(--bar-width));
		background-color: #fff;
		aspect-ratio: 1 / 1;
	}
}
