.signup {
	&-container {
		display: flex;
		height: 100vh;
	}
	&-aside {
		width: 40%;
		overflow: hidden;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: 1200px) {
			width: 50%;
		}
		@media screen and (max-width: 900px) {
			width: 60%;
		}
		@media screen and (max-width: 769px) {
			position: absolute;
			inset-inline-start: 50%;
			inset-block-start: 50%;
			transform: translate(-50%, -50%);
			background-color: #fffe;
			padding: 2rem;
			border-radius: 2rem;
			&:lang(ar) {
				transform: translate(50%, -50%);
			}
		}
		@media screen and (max-width: 650px) {
			width: 70%;
		}
		@media screen and (max-width: 550px) {
			width: 80%;
		}
		@media screen and (max-width: 450px) {
			width: 90%;
		}
	}
	&-back {
		all: unset;
		cursor: pointer;
		position: absolute;
		inset-block-start: 1.5rem;
		inset-inline-start: 1.5rem;
		&:lang(ar) {
			transform: rotate(180deg);
		}
	}
}
