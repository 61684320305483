.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-container {
		border: 2px solid #f7a821;
		background-color: #fff;
		border-radius: 3rem;
		padding: 2rem;
		width: 40%;
		max-width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		&-cart {
			width: 2.5rem;
		}
		&-text {
			width: 80%;
			font-size: 1.325rem;
			color: #808080;
			font-weight: 400;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		&-ticket {
			width: 50%;
		}
		&-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 1.125rem;
			font-weight: 400;
			color: #808080;
			span {
				font-weight: 500;
			}
		}
		&-hint {
			width: 90%;
			text-align: center;
			font-size: 1.25rem;
			color: #f7a821;
		}
		&-actions {
			width: 90%;
			display: flex;
			gap: 2rem;
			button {
				width: 50%;
			}
		}
	}
}
