.category-header {
	padding-block: 1.5rem;
	padding-inline: var(--padding-x);
	display: flex;
	gap: 1rem;
	z-index: -1;
	box-shadow: 0px 0.5rem 0.5rem 0px #00000026;
	@media screen and (max-width: 769px) {
		padding-block: 0.75rem;
		overflow: scroll;
	}
}
