.container {
	// display: flex;
	// align-items: center;
	background-color: transparent;
	// border: 1px solid #e6e2e2;
	border-radius: 6px;
	height: 45px;
	position: relative;
	cursor: pointer;

	.radio-input {
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	.radio-input-lable {
		cursor: pointer;
		border-radius: 6px;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		box-sizing: border-box;
		flex-direction: column;
		&:lang(ar) {
			left: auto;
			right: 0;
		}
		&-image {
			border-radius: 0.25rem;
		}
		&-container {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
		.radio-input-content {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-inline: 2rem;
			.radio-input-lable-text {
				font-size: 1rem;
				font-weight: 400;
				color: #3c3c43;
			}
			.radio-input-lable-image {
				height: 1.5rem;
			}
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 20px;
			width: 1.125rem;
			height: 1.125rem;
			border: 1px solid #bfbfbf;
			border-radius: 50%;
			box-sizing: border-box;
		}
		&:lang(ar) {
			&::after {
				left: auto;
				right: 0;
			}
		}
	}
	.radio-input:checked ~ .radio-input-lable {
		&:after {
			border: 2px solid #ffffff;
			background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
			outline: 1px solid #f7a821;
		}
	}
	.radio-input {
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
