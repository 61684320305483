.product {
	width: 100%;
	background-color: #fff;
	padding: 0.75rem;
	border-radius: 1rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;
	&-won {
		background-color: #de1a87;
		color: #fff;
		.product-name {
			color: #fff;
		}
		.product-state {
			color: #fff;
			text-transform: uppercase;
		}
	}
	&-favourite {
		position: absolute;
		inset-block-start: 0.75rem;
		inset-inline-end: 0.75rem;
		cursor: pointer;
	}
	&-content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	&-winner-ticket {
		position: absolute;
		inset-inline-end: 0;
		transform: translateX(20%);
		&::after {
			content: attr(aria-winner);
			position: absolute;
			display: grid;
			place-content: center;
			inset: 0;
			color: #fff;
			font-weight: 500;
			font-size: 1.125rem;
			transform: translateY(-16%);
		}
		&:lang(ar) {
			transform: translateX(-20%);
		}
	}
	&-border {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 2px;
		box-shadow: 0 0 10px 1px #0002;
		border-radius: 1rem;
		&:hover {
			background: linear-gradient(#f7a821 0%, #dd1a82 100%);
			overflow: hidden;
		}
		&:has(.product-won) {
			background-color: #dd1a82;
		}
	}
	&-image {
		object-fit: contain;
		max-width: 100%;
		max-height: 90%;
		aspect-ratio: 1/1;
		&-container {
			position: relative;
			background-color: #f2f2f2;
			width: 100%;
			aspect-ratio: 1/1;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 1rem;
			padding-block: 0.75rem;
		}
	}
	&-name {
		font-weight: 500;
		font-size: 1.125rem;
		color: #222;
	}
	&-extra {
		&-date {
			font-size: 1rem;
			color: #bfbfbf;
		}
	}
	&-footer {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		align-items: center;
		gap: 0.5rem;
		border-block-start: 1px solid #bfbfbf;
		padding-block-start: 0.75rem;
	}
	&-state {
		font-size: 1rem;
		font-weight: 500;
		color: #3c3c43;
		text-transform: capitalize;
	}
	&-action {
		all: unset;
		cursor: pointer;
		font-size: 1rem;
		font-weight: 500;
		color: #f7a920;
	}
}

.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-icon {
		position: absolute;
		padding: 0.75rem;
		background-color: #f3f3f3;
		border-radius: 0.75rem;
		inset-block-start: 1rem;
		inset-inline-end: 1rem;
	}
	&-container {
		position: relative;
		background-color: #fff;
		border-radius: 1rem;
		padding-block: 2rem;
		padding-inline: 2rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		cursor: default;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		overflow: hidden;
		p + div {
			max-width: 500px !important;
			width: 100% !important;
		}
		button {
			width: 100%;
			padding-block: 0.75rem;
			padding-inline: 9rem;
			font-size: 1rem;
			font-weight: 500;
		}
	}
	&-header {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
	}
	&-text {
		font-size: 1.25rem;
		color: #808080;
		text-align: center;
	}
	&-hint {
		font-size: 0.875rem;
		color: #808080;
		text-align: center;
	}
	button {
		width: 70%;
		align-self: center;
		padding-inline: 0;
	}
}

.product-details-content-wheel-text-sec {
	position: absolute;
	font-weight: 400;
	color: #fff;
	font-size: 1rem;
	background: linear-gradient(5.24deg, #ffd16a 3.69%, #f5a71f 18.12%, #dc1586 93.88%);
	padding: 0.25rem 1.5rem;
	align-self: flex-start;
	border-radius: 3rem;
	max-width: 10rem;
	text-transform: uppercase;
	inset-block-end: 1rem;
	span {
		font-weight: 700;
	}
}
