.profile {
	width: 35%;
	box-shadow: 0 0 10px 2px #0002;
	border-radius: 2rem;
	overflow: hidden;
	padding-block-end: 2rem;
	min-width: 29.25rem;
	background-color: #fff;
	@media screen and (max-width: 1200px) {
		box-shadow: none;
		border-radius: 0;
		position: absolute;
		inset-inline-start: -100%;
		inset-block: 0;
		overflow: visible;
		z-index: 1000;
		transition: all 400ms ease-in-out;
	}
	@media screen and (max-width: 500px) {
		width: 100%;
		min-width: 0;
	}
	&-data {
		padding-block: 2rem 3rem;
		background: linear-gradient(174.76deg, #ffd16a 3.68%, #f5a71f 22.47%, #dc1586 93.87%);
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		color: #fff;
		@media screen and (max-width: 1200px) {
			background: linear-gradient(to right, #f7a821 0vw, #dd1a82 100vw);
		}
	}
	&-manage-data {
		cursor: pointer;
		font-size: 0.875rem;
		text-decoration: underline;
	}
	&-image {
		width: 7rem;
		aspect-ratio: 1;
		object-fit: cover;
		border-radius: 50%;
		&-container {
			position: relative;
			cursor: pointer;
		}
		&-upload {
			background-color: #fff;
			aspect-ratio: 1;
			display: grid;
			place-items: center;
			width: 2.5rem;
			border-radius: 50%;
			position: absolute;
			inset-block-end: 0;
			inset-inline-end: 0;
			cursor: pointer;
			&-icon {
				width: 40%;
			}
		}
	}
	&-name {
		font-weight: 500;
		font-size: 1rem;
	}
	&-level {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		font-size: 0.875rem;
		background-color: #fff2;
		padding: 0.25rem 0.75rem;
		border-radius: 1rem;
	}
	&-details {
		display: flex;
		gap: 0.5rem;
		padding-inline: 2rem;
		position: relative;
		margin-block-start: -2rem;
		@media screen and (max-width: 500px) {
			padding-inline: 0.5rem;
		}
		&-card {
			cursor: pointer;
			background-color: transparent;
			box-shadow: 0 10px 10px 2px #0002;
			width: 100%;
			padding-block: 3rem;
			border-radius: 1rem;
			display: grid;
			place-items: center;
			position: relative;
			overflow: hidden;
			font-weight: 500;
			height: 9.25rem;
			&::before {
				content: '';
				position: absolute;
				inset-block-start: 0;
				width: 45%;
				height: 2rem;
				display: block;
				background-color: transparent;
				border-radius: 0 0 2rem 2rem;
				box-shadow: 0 0 0 500px #fff;
			}
			&-content {
				position: absolute;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 0.25rem;
			}
			&-number {
				font-size: 1.5rem;
				background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
				background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
				text-align: center;
			}
			&-text {
				font-size: 0.75rem;
				color: #808080;
			}
		}
	}
	&-tabs {
		width: 100%;
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding: 2rem;
		@media screen and (max-width: 500px) {
			padding-inline: 0.5rem;
		}
	}
	&-tab {
		display: flex;
		gap: 0.5rem;
		padding: 0.75rem 1rem;
		border-radius: 0.75rem;
		align-items: center;
		cursor: pointer;
		&:last-child {
			align-self: flex-start;
		}
		&:hover:not(:last-child) {
			background-color: #f7a9203d;
			transition: all 200ms ease-in-out;
		}
		&-selected {
			background-color: #f7a9203d;
			transition: all 200ms ease-in-out;
			.profile-tab-text {
				background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
				background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
			}
		}
		&-text {
			font-size: 1rem;
		}
		&-image {
			width: 2rem;
			height: 1.5rem;
			object-fit: contain;
		}
	}
}

.open {
	@media screen and (max-width: 1200px) {
		inset-inline-start: 0;
		z-index: 1;
		background-color: #fff;
	}
}

.menu-close {
	position: absolute;
	width: 2rem;
	height: 2rem;
	inset-inline-end: 1rem;
	inset-block-start: 1rem;
	cursor: pointer;
}
