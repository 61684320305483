.success {
	padding-inline: 6.5rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	position: relative;
	@media screen and (max-width: 1200px) {
		padding-inline: 4rem;
	}
	@media screen and (max-width: 1024px) {
		padding-inline: 2rem;
	}
	@media screen and (max-width: 769px) {
		padding-inline: 0;
	}
	&-logo {
		width: 50%;
	}
	&-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 1rem;
		&-body {
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.25;
			color: #000;
			align-self: center;
		}
		&-text {
			text-align: center;
		}
		&-image {
			width: 4rem;
			max-width: 100%;
			align-self: center;
			margin-block-start: 6rem;
		}
	}
	&-actions {
		margin-block-start: 5rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		.language-switcher-container {
			display: flex;
			align-self: center;
			margin-block-start: 2.5rem;
		}
	}
}
