.fire-wheel {
	display: flex;
	flex-direction: column;
	border-block-end: 1px solid #ededed;
	cursor: pointer;
	&-header {
		font-weight: 600;
		font-size: 2.5rem;
		color: #808080;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		gap: 0.25rem;
		align-self: start;
		padding-block: 0.5rem;
		padding-inline: 0.5rem;
		background: linear-gradient(to left, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		// border-block-end: 3px solid #f59b2a;
	}
	&-product {
		align-self: center;
		display: flex;
		padding: 5px;
		margin-block: 3rem;
		background-image: linear-gradient(5.24deg, #ffd16a 3.69%, #f5a71f 18.12%, #dc1586 93.88%);
		position: relative;
		border-radius: 9rem;
		@media screen and (max-width: 650px) {
			width: 100%;
		}
		&-container {
			border-radius: 9rem;
			background-color: #fff;
			display: flex;
			align-items: center;
			gap: 1rem;
			height: 9rem;
			padding-block: 1rem;
			padding-inline: 2rem 12rem;
			@media screen and (max-width: 650px) {
				padding-inline: 2rem 10rem;
				width: 100%;
			}
			@media screen and (max-width: 550px) {
				height: auto;
				padding-inline: 2rem 10rem;
				// padding-inline: 1rem 5rem;
			}
		}
		&-text {
			font-size: 1.5rem;
			font-weight: 500;
			&-special {
				font-size: 1.75rem;
				background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
				background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
				@media screen and (max-width: 550px) {
					font-size: 1.5rem;
				}
				@media screen and (max-width: 400px) {
					font-size: 1.25rem;
				}
			}
			@media screen and (max-width: 550px) {
				font-size: 1.25rem;
			}
		}
		&-image {
			height: 7rem;
			@media screen and (max-width: 550px) {
				height: 5rem;
			}
			@media screen and (max-width: 400px) {
				height: 4rem;
			}
		}
	}
	&-image {
		display: grid;
		place-items: center;
		width: 13rem;
		position: absolute;
		inset-block-start: 50%;
		inset-inline-end: 0;
		height: 13rem;
		transform: translateY(-50%) translateX(25%) rotate(0deg);
		transform-origin: center center;
		overflow: hidden;
		border-radius: 100%;
		animation: rotate 1s linear forwards infinite;
		&:lang(ar) {
			animation: rotateAr 1s linear forwards infinite;
			transform: translateY(-50%) translateX(-25%) rotate(0deg);
		}
		img {
			width: 100%;
			height: 100%;
		}
		@media screen and (max-width: 650px) {
			transform: translateY(-50%) translateX(20%) rotate(0deg);
			&:lang(ar) {
				transform: translateY(-50%) translateX(-20%) rotate(0deg);
			}
		}
		@media screen and (max-width: 550px) {
			height: 12rem;
			width: 12rem;
			transform: translateY(-50%) translateX(20%) rotate(0deg);
			&:lang(ar) {
				transform: translateY(-50%) translateX(-20%) rotate(0deg);
			}
		}
		@media screen and (max-width: 400px) {
			height: 10rem;
			width: 10rem;
		}
	}
}

@keyframes rotate {
	0% {
		transform: translateY(-50%) translateX(25%) rotate(0deg);
	}
	100% {
		transform: translateY(-50%) translateX(25%) rotate(360deg);
	}
}

@keyframes rotateAr {
	0% {
		transform: translateY(-50%) translateX(-25%) rotate(0deg);
	}
	100% {
		transform: translateY(-50%) translateX(-25%) rotate(360deg);
	}
}

@media screen and (max-width: 650px) {
	@keyframes rotate {
		0% {
			transform: translateY(-50%) translateX(20%) rotate(0deg);
		}
		100% {
			transform: translateY(-50%) translateX(20%) rotate(360deg);
		}
	}
	@keyframes rotateAr {
		0% {
			transform: translateY(-50%) translateX(-20%) rotate(0deg);
		}
		100% {
			transform: translateY(-50%) translateX(-20%) rotate(360deg);
		}
	}
}
