.other {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	@media screen and (max-width: 1200px) {
		margin-block-start: 2rem;
	}
	&-transactions {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-header {
			font-weight: 500;
			font-size: 1.5rem;
			color: #3c3c43;
		}
		&-list {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
		&-empty {
			width: 70%;
			align-self: center;
			justify-self: center;
			margin-inline: auto;
		}
	}
}
