.deliver {
	&-container {
		@media screen and (max-width: 1200px) {
			margin-block-start: 2rem;
		}
	}
	&-card {
		padding-block: 0.625rem;
		padding-inline: 0.625rem 1rem;
		border: 1px solid #f3f3f3;
		border-radius: 0.75rem;
		display: flex;
		align-items: center;
		gap: 1rem;
		&-image {
			max-height: 8rem;
		}
		&-product {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			&-title {
				font-weight: 500;
				font-size: 1.25rem;
				color: #000;
			}
			&-date {
				font-size: 0.75rem;
				font-weight: 500;
				color: #808080;
			}
		}
		&-wheel {
			font-size: 0.875rem;
			font-weight: 400;
			color: #808080;
			span {
				font-weight: 500;
			}
		}
		&-winner {
			font-size: 0.875rem;
			color: #808080;
			span {
				font-weight: 500;
				color: #000;
			}
		}
		button {
			width: auto;
			margin-inline-start: auto;
			font-size: 1rem;
			padding: 0.5rem 1rem;
		}
	}
}

.form {
	padding-block: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	&-logo {
		width: 50%;
	}
	&-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 0.5rem;
		&-title {
			font-size: 1rem;
			font-weight: 500;
			color: #000;
		}
		&-body {
			font-size: 0.75rem;
			font-weight: 400;
			line-height: 1.25;
			color: #808080;
		}
	}
	&-input-container {
		margin-block-start: 0.5rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	&-signup-text {
		align-self: center;
		color: #808080;
		font-size: 1rem;
		text-align: center;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		a {
			font-size: 1rem;
			text-decoration: none;
			color: #de1a87;
			font-weight: 500;
		}
	}
	&-actions {
		margin-block-start: 1.5rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		.language-switcher-container {
			display: flex;
			align-self: center;
		}
	}
	.forget-password {
		margin-block-start: 0.25rem;
		font-size: 0.875rem;
		font-weight: 500;
		text-decoration: none;
		color: #808080;
		align-self: flex-start;
	}
}

.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-container {
		background-color: #fff;
		border-radius: 1rem;
		padding-block: 3rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		padding-inline: 1.25rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		button {
			width: auto;
			margin-top: 2rem;
			padding-block: 0.75rem;
			padding-inline: 9rem;
			font-size: 1rem;
			font-weight: 500;
		}
	}
	&-text {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		&-secondary {
			font-size: 1rem;
			color: #808080;
		}
	}
	&-image {
		max-height: 29rem;
		max-width: 100%;
	}
}
