.my-profile {
	padding-block-end: 3rem;
	@media screen and (max-width: 1200px) {
		padding-block-end: 0;
	}
	&-header {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		padding-block: 2rem;
		text-transform: capitalize;
		background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
		@media screen and (max-width: 1200px) {
			background: linear-gradient(to right, #f7a821 0vw, #dd1a82 100vw);
		}
		&-container {
			&.open {
				@media screen and (max-width: 500px) {
					display: none;
				}
			}
		}
	}
	&-container {
		margin-block-start: -4.5rem;
		display: flex;
		align-items: flex-start;
		padding-inline: var(--padding-x);
		padding-block-start: 3rem;
		gap: 1.5rem;
		position: relative;
		min-height: 100vh;
		@media screen and (max-width: 1200px) {
			margin-block-start: 0;
			padding-inline: 0;
			padding-block-start: 0;
			justify-content: center;
		}
	}
}

.open {
	min-height: max(101vh, 60rem);
	&-header {
		@media screen and (max-width: 500px) {
			display: none;
		}
	}
}
