.category {
	width: 100%;
	padding-inline: var(--padding-x);
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
	@media screen and (max-width: 600px) {
		flex-direction: column;
		align-items: center;
	}
	&-header {
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: capitalize;
		color: #fff;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		padding-block: 2rem;
		background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
	&-container {
		padding-block-end: 3rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
	&-product {
		width: calc(calc(100% / 4) - calc(0.5rem * 3 / 4));
		@media screen and (max-width: 1024px) {
			width: calc(calc(100% / 3) - calc(0.5rem * 2 / 3));
		}
		@media screen and (max-width: 900px) {
			width: calc(calc(100% / 2) - calc(0.5rem * 1 / 2));
		}
		@media screen and (max-width: 600px) {
			width: 60%;
		}
		@media screen and (max-width: 500px) {
			width: 80%;
		}
		@media screen and (max-width: 400px) {
			width: 100%;
		}
	}
	&-pagination {
		align-self: center;
	}
}
