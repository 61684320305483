.voucher-card {
	width: 100%;
	height: 100%;
	background-color: #fff;
	box-shadow: 0px 3px 12.01px 0px #00000026;
	border-radius: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.75rem;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	&-image {
		width: 100%;
		height: 10rem;
		max-height: 10rem;
		object-fit: contain;
		&-container {
			padding: 1rem;
			width: 100%;
			aspect-ratio: 1;
			// max-height: 10rem;
			// max-height: 100%;
			background-color: #f2f2f2;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 1rem;
			margin-bottom: 0.5rem;
		}
	}
	&-title {
		font-size: 1.25rem;
		text-transform: uppercase;
		font-weight: 700;
		color: #f35401;
	}
	&-text {
		font-size: 0.875rem;
		color: #000;
		font-weight: 700;
		padding-block-end: 1rem;
	}
	&-redeem {
		font-size: 0.875rem;
		padding-top: 0.5rem;
		border-top: 1px solid #c4c4c4;
		color: #808080;
		margin-top: auto;
		display: flex;
		align-items: center;
		width: calc(100% - 3.5rem);
		img {
			width: 1.5rem;
			height: 1.5rem;
			object-fit: contain;
			margin-inline-end: 0.5rem;
		}
	}
	&-brand {
		width: 95%;
		height: 95%;
		object-fit: contain;
		background-color: #fff;
		border-radius: 100%;
		padding: 5px;
		&-container {
			width: 3.5rem;
			height: 3.5rem;
			display: grid;
			place-items: center;
			position: absolute;
			inset-inline-end: 0.5rem;
			inset-block-end: 0.75rem;
			border-radius: 100%;
			background-color: #e02978;
		}
	}
}

.redeem {
	position: absolute;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	color: #fff;
	background-color: #f35401;
	padding: 1.5rem 3rem 1.5rem 1.5rem;
	border-radius: 3rem;
	span {
		font-weight: 700;
	}
	inset-block-start: 2.5rem;
	inset-inline-end: -2.5rem;
}
