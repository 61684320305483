.footer {
	background-image: linear-gradient(180deg, #f59b2a 0%, #e02978 100%);
	padding: 2.5rem var(--padding-x);
	color: #fff;
	display: flex;
	flex-direction: column;
	&-sections {
		display: flex;
		gap: 4.75rem;
		padding-block-end: 5rem;
		@media screen and (max-width: 1024px) {
			gap: 2rem;
			flex-direction: column;
		}
	}
	&-copyrights {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.25rem;
		font-size: 1.25rem;
		@media screen and (max-width: 500px) {
			flex-direction: column;
		}
	}
	&-first {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		&-logo {
			max-width: 15.625rem;
		}
		&-contact {
			display: flex;
			flex-direction: column;
			gap: 1.25rem;
			@media screen and (max-width: 1024px) {
				flex-direction: row;
				flex-wrap: wrap;
			}
			&-header {
				font-weight: 700;
				font-size: 1.25rem;
				@media screen and (max-width: 1024px) {
					width: 100%;
				}
			}
			&-method {
				display: flex;
				align-items: start;
				gap: 0.75rem;
				font-weight: 500;
				&-name {
					display: flex;
					flex-direction: column;
					gap: 0.125rem;
				}
				&-number {
					font-weight: 600;
				}
			}
		}
		&-download {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			&-header {
				font-weight: 700;
				font-size: 1.25rem;
			}
			&-methods {
				display: flex;
				gap: 1.25rem;
				// @media screen and (max-width: 500px) {
				// 	flex-direction: column;
				// }
			}
			&-method {
				width: 100%;
				max-width: 100%;
				&:nth-child(1) {
					width: 90%;
				}
				&-image {
					max-width: 100%;
				}
			}
		}
	}
	&-second,
	&-third {
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
		&-header {
			font-weight: 600;
			font-size: 1.25rem;
			padding-block-end: 1rem;
			border-block-end: 3px solid #fff;
			align-self: flex-start;
		}
		&-list {
			font-weight: 500;
			display: flex;
			flex-direction: column;
			gap: 0.75rem;
			padding-inline-start: 1.5rem;
			@media screen and (max-width: 1024px) {
				flex-direction: row;
				gap: 1rem;
				list-style: none;
				padding: 0;
				flex-wrap: wrap;
			}
			&-item {
				cursor: pointer;
				&::marker {
					font-size: 0.865rem;
				}
			}
		}
	}
}
