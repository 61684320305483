.category {
	width: 100%;
	padding-inline: var(--padding-x);
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
	@media screen and (max-width: 600px) {
		flex-direction: column;
		align-items: center;
	}
	&-header {
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: capitalize;
		color: #fff;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		padding-block: 2rem;
		background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
	&-content {
		padding: 2rem 6rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		width: 100%;
		max-width: 60rem;
		&-first {
			width: 100%;
			position: relative;
			display: flex;
			gap: 1.5rem;
			border: 1px solid #c4c4c4;
			border-radius: 1.5rem;
			padding: 2rem 1rem 2rem 1rem;
			&-image {
				aspect-ratio: 1;
				height: 20rem;
				object-fit: contain;
				border-radius: 1.5rem;
			}
			&-logo {
				position: absolute;
				inset-inline-end: 1rem;
				inset-block-end: 1rem;
			}
		}
		&-second {
			position: relative;
			border: 1px solid #c4c4c4;
			border-radius: 1.5rem;
			padding: 2rem;
			color: #808080;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			&-balance {
				font-size: 1.5rem;
				font-weight: 500;
				color: #808080;
				span {
					background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
					background-clip: text;
					-webkit-text-fill-color: transparent;
					-moz-text-fill-color: transparent;
				}
				&-redeemed {
					// font-size: 1.25rem;
					font-weight: 500;
				}
			}
			&-code {
				font-size: 1.25rem;
				font-weight: 400;
				color: #808080;
				border-top: 1px solid #c4c4c4;
				padding-top: 1rem;
				span {
					color: #22bc50;
				}
			}
			&-amount {
				position: absolute;
				font-size: 1.5rem;
				background-color: #f35401;
				color: #fff;
				font-weight: 500;
				padding: 0.5rem 1rem;
				border-radius: 2rem;
				inset-block-start: 1rem;
				inset-inline-end: -1rem;
			}
		}
	}
}

.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-icon {
		position: absolute;
		padding: 0.75rem;
		background-color: #f3f3f3;
		border-radius: 0.75rem;
		inset-block-start: 1rem;
		inset-inline-end: 1rem;
	}
	&-container {
		position: relative;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		color: #fff;
		text-align: center;
		border-radius: 1rem;
		padding-block: 2rem;
		padding-inline: 1rem;
		width: 35%;
		min-width: 30rem;
		display: flex;
		flex-direction: column;
		cursor: default;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		button {
			width: 100%;
			padding-block: 0.75rem;
			padding-inline: 9rem;
			font-size: 1rem;
			font-weight: 500;
			border-radius: 0.75rem;
		}
	}
	&-header {
		text-align: center;
		font-size: 1rem;
		font-weight: 500;
	}
	&-image {
		max-height: 29rem;
		max-width: 100%;
		margin-top: -4rem;
	}
	&-list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-item {
			display: flex;
			justify-content: space-between;
			font-size: 0.75rem;
			text-transform: capitalize;
			&-text {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				span {
					font-weight: 400;
					font-size: 0.625rem;
				}
			}
			&:last-of-type {
				font-weight: 700;
			}
			span {
				color: #696874;
			}
		}
	}
}
