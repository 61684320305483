.balance {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	@media screen and (max-width: 768px) {
		img {
			height: 4rem;
		}
	}
	&-empty {
		padding: 0;
		margin: 0;
		width: 50%;
		align-self: center;
		aspect-ratio: 1;
		object-fit: cover;
	}
	&-header {
		font-weight: 500;
		font-size: 5.75rem;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		text-align: center;
		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			@media screen and (max-width: 400px) {
				gap: 0.5rem;
			}
		}
		@media screen and (max-width: 768px) {
			font-size: 3.75rem;
			margin-block-start: 1.5rem;
		}
		@media screen and (max-width: 400px) {
			font-size: 3rem;
		}
	}
	&-hint {
		font-size: 1rem;
		font-weight: 400;
		color: #808080;
		text-align: center;
	}
	button {
		align-self: center;
		width: 50%;
		border-radius: 1.25rem;
	}
	&-container {
		width: 100%;
		display: flex;
		gap: 0.5rem;
		flex-wrap: wrap;
	}
	&-product {
		width: calc(calc(100% / 3) - calc(0.5rem * 2 / 3));
	}
	&-transactions {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-header {
			font-weight: 500;
			font-size: 1.5rem;
			color: #3c3c43;
		}
		&-list {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
	}
}
