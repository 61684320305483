.category-chip {
	cursor: pointer;
	background-color: #f3f9fb;
	color: #222;
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	gap: 0.375rem;
	padding: 0.625rem 0.875rem;
	border-radius: 1.125rem;
	text-transform: capitalize;
	&-container {
		// border-radius: 1.25rem;
		// padding: 2px;
		// background-image: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
	&-selected {
		color: white;
		background-image: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
}
