.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-container {
		background-color: #fff;
		border-radius: 1rem;
		padding-block-end: 3rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 35rem;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		padding-inline: 1.25rem;
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		align-items: center;
		margin-top: -6rem;
		&-actions {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			button {
				width: 100%;
				padding-block: 1rem;
				font-size: 1.25rem;
				font-weight: 500;
			}
		}
	}
	&-title {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
	}
	&-text {
		text-align: center;
		font-size: 1rem;
		font-weight: 400;
		color: #808080;
	}
	&-image {
		max-height: 29rem;
		max-width: 100%;
		margin-top: -5rem;
	}
}
