.product {
	width: 100%;
	background-color: #fff;
	padding: 0.5rem;
	border-radius: 1rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;
	cursor: pointer;
	&-favourite {
		position: absolute;
		inset-block-start: 1rem;
		inset-inline-end: 1rem;
	}
	&-border {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 2px;
		box-shadow: 0 0 10px 1px #0002;
		border-radius: 1rem;
		&:hover {
			background: linear-gradient(#f7a821 0%, #dd1a82 100%);
			overflow: hidden;
		}
	}
	&-image {
		object-fit: contain;
		max-width: 100%;
		max-height: 90%;
		&-container {
			background-color: #f2f2f2;
			width: 100%;
			height: 20rem;
			aspect-ratio: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 1rem;
			padding-block: 0.75rem;
		}
	}
	&-name {
		font-weight: 500;
		font-size: 1rem;
		color: #222;
	}
	&-container {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	&-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}
	&-extra {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&-price {
			display: flex;
			align-items: baseline;
			gap: 0.25rem;
			font-size: 0.75rem;
			&-number {
				font-weight: 700;
				font-size: 1rem;
				color: #222;
			}
		}
		&-slots {
			font-weight: 400;
			color: #fff;
			background: radial-gradient(46.62% 48.85% at 49.85% 51.31%, #e23373 50%, #f7ac38 100%);
			align-self: flex-start;
			border-radius: 3rem;
			width: fit-content;
			text-transform: uppercase;
			font-size: 0.875rem;
			padding: 0.25rem 0.75rem;
			span {
				font-weight: 700;
			}
		}
		// &-slots {
		// 	&-number {
		// 		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		// 		background-clip: text;
		// 		-webkit-text-fill-color: transparent;
		// 		-moz-text-fill-color: transparent;
		// 	}
		// }
	}
	&-footer {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		border-block-start: 1px solid #ededed;
		padding-block-start: 0.5rem;
		&-text {
			font-size: 0.75rem;
			font-weight: 500;
			color: #808080;
			text-transform: capitalize;
		}
		&-image {
			height: 80%;
		}
	}
}
