.refer {
	padding-inline: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	@media screen and (max-width: 1200px) {
		margin-block-start: 2rem;
		padding-inline: 0;
	}
	&-content {
		margin-block-start: 6rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.5rem;
		&-image {
			max-width: 100%;
			height: 12rem;
			object-fit: contain;
		}
		&-title {
			font-weight: 500;
			font-size: 1.5rem;
			color: #212529;
			span {
				color: #f7a920;
			}
		}
		&-desc {
			font-size: 1rem;
			color: #3c3c43;
		}
	}
	&-code {
		margin-block-start: 3rem;
		margin-block-end: 2rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		&-title {
			font-size: 1.5rem;
			font-weight: 500;
			color: #343a40;
			text-transform: capitalize;
		}
		&-code {
			padding: 1rem 1rem;
			border-radius: 0.5rem;
			border: 1px solid #dfe2e4;
			color: #6c757d;
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			white-space: nowrap;
			position: relative;
			span {
				margin-inline-end: 1rem;
				overflow: hidden;
				width: 70%;
				text-wrap: auto;
			}
		}
		&-copy {
			all: unset;
			color: #fff;
			background-color: #f7a920;
			padding: 0.5rem 1rem;
			border-radius: 0.25rem;
			width: auto;
			cursor: pointer;
			position: absolute;
			inset-block: 0.5rem;
			inset-inline-end: 1rem;
		}
	}
}

.terms {
	margin-block-start: 1rem;
	font-size: 1.25rem;
	font-weight: 400;
	color: #f7a920;
	text-align: center;
	text-transform: capitalize;
}
