.card {
	padding-block: 0.625rem;
	padding-inline: 0.625rem 3rem;
	border: 1px solid #f3f3f3;
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	cursor: pointer;
	&-icon {
		padding: 0.75rem;
		background-color: #f3f3f3;
		border-radius: 0.75rem;
	}
	&-product {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		&-title {
			font-weight: 400;
			font-size: 1rem;
		}
		&-date {
			font-size: 0.75rem;
			color: #a7a9b7;
		}
	}
	&-amount {
		margin-inline-start: auto;
		font-size: 0.875rem;
	}
}

.up {
	color: #07a26a;
}

.down {
	color: #ce1126;
}

.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-icon {
		position: absolute;
		padding: 0.75rem;
		background-color: #f3f3f3;
		border-radius: 0.75rem;
		inset-block-start: 1rem;
		inset-inline-end: 1rem;
	}
	&-container {
		position: relative;
		background-color: #fff;
		border-radius: 1rem;
		padding-block: 2rem;
		padding-inline: 1rem;
		width: 35%;
		min-width: 30rem;
		display: flex;
		flex-direction: column;
		cursor: default;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		button {
			width: 100%;
			padding-block: 0.75rem;
			padding-inline: 9rem;
			font-size: 1rem;
			font-weight: 500;
		}
	}
	&-header {
		text-align: center;
		font-size: 1rem;
		font-weight: 500;
	}
	&-image {
		max-height: 29rem;
		max-width: 100%;
		margin-top: -4rem;
	}
	&-list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-item {
			display: flex;
			justify-content: space-between;
			font-size: 0.75rem;
			text-transform: capitalize;
			&-text {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				span {
					font-weight: 400;
					font-size: 0.625rem;
				}
			}
			&:last-of-type {
				font-weight: 700;
			}
			span {
				color: #696874;
			}
		}
	}
}
