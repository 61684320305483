.big-wins {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	@media screen and (max-width: 1200px) {
		margin-block-start: 2rem;
	}
	&-header {
		font-weight: 500;
		font-size: 1.5rem;
		color: #3c3c43;
	}
	&-list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	&-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: 2rem;
		margin-block-start: -2rem;
		&-image {
			margin-block: 2rem;
		}
		&-number {
			font-size: 6rem;
			font-weight: 500;
			background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
		&-text {
			color: #bfbfbf;
			font-size: 1.25rem;
		}
		&-action {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			margin-block-start: 2rem;
			button {
				margin-block-start: 2rem;
				align-self: center;
				width: 60%;
				@media screen and (max-width: 768px) {
					width: 80%;
				}
				@media screen and (max-width: 600px) {
					width: 90%;
				}
				@media screen and (max-width: 400px) {
					width: 100%;
				}
			}
			&-image {
				position: absolute;
				inset-block-start: 1rem;
				inset-inline-end: 6rem;
				transform: translate(50%, -50%);
				&:lang(ar) {
					transform: translate(-50%, -50%);
				}
				@media screen and (max-width: 1700px) {
					inset-block-start: 2rem;
					height: 15rem;
				}
				@media screen and (max-width: 1500px) {
					inset-block-start: 1.75rem;
					height: 12rem;
					inset-inline-end: 5rem;
				}
			}
		}
		&-hint {
			text-align: center;
			font-weight: 500;
			font-size: 1.125rem;
			color: #808080;
		}
	}
}
