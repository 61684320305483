.validation {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding-inline-start: 1rem;
	&-icon {
		width: 0.625rem;
	}
}
.normal {
	font-size: 0.75rem;
	color: #808080;
}

.success {
	font-size: 0.75rem;
	color: #07a26a;
}

.error {
	font-size: 0.75rem;
	color: #fb5b5b;
}
