.splash {
	width: 60%;
	height: 100vh;
	position: relative;
	overflow: hidden;
	background-image: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	@media screen and (max-width: 769px) {
		width: 100%;
	}
	&-logo {
		max-width: 50%;
		position: absolute;
		inset-block-start: 25.5%;
		inset-inline-start: 50%;
		transform: translateX(-50%);
		&:lang(ar) {
			transform: translateX(50%);
		}
		cursor: pointer;
		@media screen and (max-width: 769px) {
			inset-block-start: 2rem;
		}
	}
	&-image {
		position: absolute;
		width: 130%;
		inset-inline-end: 4%;
		inset-block-start: 39%;
		&:lang(ar) {
			transform: scaleX(-1);
		}
		@media screen and (max-width: 600px) {
			inset-inline-end: 0;
			inset-block-start: auto;
			inset-block-end: -2rem;
		}
	}
}
