.navbar {
	padding-block: 1.25rem;
	padding-inline: var(--padding-x);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #ededed;

	@media screen and (max-width: 769px) {
		justify-content: flex-start;
		gap: 1rem;
	}
	&-menu {
		border-radius: 0.5rem;
		width: 2.5rem;
		aspect-ratio: 1;
		background-color: #f2f2f2;
		display: none;
		flex-direction: column;
		justify-content: center;
		padding-inline: 0.5rem;
		gap: 0.25rem;
		cursor: pointer;
		@media screen and (max-width: 769px) {
			display: flex;
		}
		&-item {
			background-color: #f7a920;
			height: 0.125rem;
			border-radius: 2rem;
			&:nth-child(1) {
				width: 100%;
			}
			&:nth-child(2) {
				width: 80%;
			}
			&:nth-child(3) {
				width: 60%;
			}
		}
	}
	&-logo {
		height: 2rem;
		cursor: pointer;
	}
	&-favourites {
		display: flex;
		position: relative;
		&-number {
			position: absolute;
			inset-block-start: 0;
			inset-inline-end: 0;
			font-size: 0.625rem;
			font-weight: 500;
			background-image: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
			width: 0.875rem;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			aspect-ratio: 1;
			border-radius: 100%;
			transform: translateY(-50%) translateX(50%);
		}
	}
	&-list {
		list-style: none;
		display: flex;
		align-items: center;
		gap: 1rem;
		@media screen and (max-width: 769px) {
			display: none;
		}
		&-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			text-transform: capitalize;
			&-image {
				aspect-ratio: 1;
				border-radius: 50%;
				width: 2rem;
				object-fit: cover;
			}
			&-icon {
				aspect-ratio: 1;
				width: 1rem;
			}
			&-text {
				font-size: 0.875rem;
				font-weight: 500;
				color: #666666;
			}
		}
	}
	&-balance {
		gap: 0.25rem;
		&-text {
			font-size: 1.25rem;
			display: flex;
			align-items: baseline;
			color: #000;
			span {
				color: #000;
				font-size: 0.5rem;
			}
		}
	}
	&-topup {
		&-text {
			text-transform: capitalize;
			font-size: 1.125rem;
			color: #f7a920;
		}
	}
}
