.layout-main {
	padding-inline: var(--padding-x);
	overflow-x: hidden;
	background-color: #fff;
}

.layout-main-no-padding {
	padding-inline: 0;
	background-color: #fff;
}
