.success {
	width: 100%;
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4rem;
	background-image: url(../../assets/images/confetti.png);
	background-repeat: no-repeat;
	background-position: center;
	&-main {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		@media screen and (max-width: 768px) {
			gap: 1rem;
			width: 50%;
			max-width: 32rem;
			min-width: 20rem;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;
		&-header {
			font-size: 1.5rem;
			font-size: 500;
			background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
		&-text {
			color: #808080;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	&-actions {
		max-width: 32rem;
		min-width: 20rem;
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		& > button:nth-child(1) {
			animation: scale 1s ease-in-out infinite alternate-reverse;
		}
	}
}

@keyframes scale {
	0% {
		scale: 1;
	}
	100% {
		scale: 1.1;
	}
}
