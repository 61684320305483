.container {
	font-size: 0.625rem;
	margin-block-start: 0.5rem;
	label {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-weight: 500;
		cursor: pointer;
		p {
			color: #808080;
			font-size: 0.75rem;
			display: flex;
			align-items: center;
			gap: 0.25rem;
			font-weight: 400;
			a {
				font-size: 0.75rem;
				color: #f7a920;
				font-weight: 500;
				text-decoration: none;
			}
		}
		.check-box {
			&-error {
				width: auto;
				color: #fb5b5b;
				font-size: 0.75rem;
			}
		}
		.check-box:checked ~ .checkmark {
			border: 1px solid #f7a920;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 1rem;
			width: 1rem;
			img {
				width: 70%;
				height: 100%;
				object-fit: contain;
			}
		}
		.checkmark {
			border: 1px solid #bababa;
			background-color: white;
			border-radius: 2px;
			height: 1rem;
			width: 1rem;
			img {
				height: 0%;
				width: 0%;
			}
		}
	}
}
