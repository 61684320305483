.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-header {
		padding-block: 1.5rem 1rem;
		display: flex;
		color: #1b2029;
		font-weight: 600;
		font-size: 1.5rem;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #f2f2f2;
		position: relative;
		&-close {
			width: 1.5rem;
			aspect-ratio: 1;
			position: absolute;
			inset-block-start: 1.5rem;
			inset-inline-end: 1rem;
			transform: translateY(-50%);
			cursor: pointer;
		}
	}
	&-container {
		background-color: #fff;
		border-radius: 1rem;
		padding-block-end: 3rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
	}
	&-content {
		padding-block: 1rem;
		padding-inline: 1.25rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		align-items: center;
		&-container {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 100%;
			padding-inline: 1rem;
		}
		button {
			width: auto;
			padding-block: 0.75rem;
			padding-inline: 9rem;
			font-size: 1rem;
			font-weight: 500;
		}
	}
	&-text {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
	}
	&-image {
		width: 40%;
		aspect-ratio: 1;
		border-radius: 100%;
		position: relative;
		&:hover {
			.modal-image-delete-container {
				display: flex;
			}
		}
		&-delete {
			width: 4rem;
			&-container {
				display: none;
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #0003;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				z-index: 10;
			}
		}
		&-show {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			padding: 10px;
			object-fit: cover;
			border-radius: 100%;
		}
		&-input {
			display: none;
			&-label {
				width: 10rem;
				aspect-ratio: 1;
				border-radius: 50%;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #0003;
				border: 2px solid #f2f2f2;
				&-image {
					width: 5rem;
				}
			}
		}
	}
}

.progress-container {
	width: 100%;
	height: 100%;
	aspect-ratio: 1;
}
