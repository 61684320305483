.language-switcher {
	width: auto;
	all: unset;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	padding: 0.5rem 1rem;
	border: 1px solid #f2f2f2;
	border-radius: 0.5rem;
	&-text {
		font-size: 1rem;
	}
}
