.checkout {
	&-header {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		padding-block: 2rem;
		background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
	&-main {
		padding-block: 3rem;
		padding-inline: var(--padding-x);
		display: flex;
		gap: 11rem;
		@media screen and (max-width: 1200px) {
			gap: 5rem;
		}
		@media screen and (max-width: 900px) {
			gap: 2rem;
		}
	}
	&-details {
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}
	&-product {
		&-details {
			padding: 1.5rem;
			display: flex;
			gap: 2rem;
			border: 1px solid #e4e4e4;
			border-radius: 1rem;
			&-image {
				max-width: 8.5rem;
				height: 100%;
				object-fit: contain;
			}
			&-wrapper {
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
				flex-grow: 1;
			}
			&-header {
				font-size: 1.5rem;
				font-weight: 500;
			}
			&-wheel {
				display: flex;
				align-items: center;
				justify-content: space-between;
				@media screen and (max-width: 1150px) {
					flex-direction: column-reverse;
					gap: 0.75rem;
					justify-content: flex-start;
					align-items: flex-start;
				}
				@media screen and (max-width: 768px) {
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
				}
				@media screen and (max-width: 550px) {
					flex-direction: column-reverse;
					gap: 0.75rem;
					justify-content: flex-start;
					align-items: flex-start;
				}
				&-text {
					font-size: 0.875rem;
					display: flex;
					align-items: center;
					gap: 0.5rem;
					font-weight: 500;
					color: #808080;
					text-transform: uppercase;
					letter-spacing: 1px;
					&-sec {
						font-weight: 400;
						color: #fff;
						font-size: 1rem;
						background: radial-gradient(46.62% 48.85% at 49.85% 51.31%, #e23373 50%, #f7ac38 100%);
						padding: 0.5rem 1.5rem;
						align-self: flex-start;
						border-radius: 3rem;
						width: fit-content;
						text-transform: uppercase;
						span {
							font-weight: 700;
						}
						@media screen and (max-width: 500px) {
							font-size: 0.875rem;
							padding: 0.25rem 0.75rem;
						}
					}
				}
				&-number {
					font-weight: 500;
					font-size: 0.875rem;
					color: #808080;
					padding: 0.125rem 0.75rem;
					background-color: #d9d9d9;
					border-radius: 1rem;
				}
			}
			&-tickets {
				font-weight: 400;
				color: #808080;
				&-number {
					color: #000;
					font-weight: 500;
				}
			}
		}
	}
	&-summary {
		padding: 1.5rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		border: 1px solid #e4e4e4;
		border-radius: 1rem;
		&-header {
			font-size: 1.5rem;
			font-weight: 500;
		}
		&-content {
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}
		&-ticket,
		&-subtotal {
			display: flex;
			align-items: baseline;
			gap: 0.25rem;
			font-weight: 400;
			color: #808080;
		}

		&-ticket-price,
		&-subtotal-tickets {
			font-weight: 500;
			color: #000;
		}
		&-undersub {
			font-size: 0.875rem;
			font-weight: 400;
			color: #808080;
			span {
				text-wrap: nowrap;
				background: linear-gradient(to left, #f7a821 0%, #dd1a82 100%);
				background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
			}
		}
		&-total {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-top: 1px solid #bfbfbf;
			padding-top: 1rem;
			&-text,
			&-price {
				font-size: 1.5rem;
				font-weight: 500;
				color: #000;
				display: flex;
				align-items: baseline;
				gap: 0.25rem;
			}
			&-hint {
				font-size: 0.75rem;
				font-weight: 400;
				color: #808080;
			}
		}
		&-action {
			all: unset;
			cursor: pointer;
			text-align: center;
			border-radius: 1rem;
			background-color: #f7a821;
			color: #fff;
			font-size: 1.375rem;
			padding-block: 1.25rem;
		}
	}
	&-payment {
		align-self: flex-start;
		width: 50%;
		padding: 1rem 1.5rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		border: 1px solid #e4e4e4;
		border-radius: 1rem;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
		&-balance {
			position: absolute;
			inset-block-start: 50%;
			inset-inline-end: 4rem;
			transform: translateY(-50%);
			font-weight: 700;
			font-size: 1rem;
			color: #3c3c43;
		}
	}
}
