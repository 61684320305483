.menu {
	position: fixed;
	inset-inline-end: 0;
	inset-block: 0;
	inset-inline-start: 0;
	background-color: #0003;
	z-index: 1000;
	backdrop-filter: blur(2px);
	transform: translateX(-100%);
	cursor: pointer;
	&-close {
		position: absolute;
		inset-inline-end: 1rem;
		inset-block-start: 1rem;
		width: 1.5rem;
		aspect-ratio: 1;
		object-fit: contain;
		cursor: pointer;
	}
	&-open {
		transform: translateX(0);
	}
	&-container {
		position: relative;
		width: 30%;
		height: 100%;
		background-color: #fff;
		margin-inline-end: auto;
		cursor: default;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-block-start: 2rem;
		padding-inline: 1rem;
		@media screen and (max-width: 769px) {
			width: 50%;
		}
		@media screen and (max-width: 500px) {
			width: 80%;
		}
		&-header {
			display: flex;
			gap: 1rem;
			align-items: center;
			justify-content: space-between;
			&-profile {
				display: flex;
				align-items: center;
				gap: 1rem;
				cursor: pointer;
			}
			&-logo {
				height: 3rem;
				cursor: pointer;
				object-fit: cover;
				border-radius: 50%;
			}
			&-text {
				font-size: 1.25rem;
				font-weight: 400;
				color: #000;
			}
			&-actions {
				display: flex;
				gap: 1rem;
				align-items: center;
			}
			&-favourites {
				display: flex;
				position: relative;
				cursor: pointer;
				&-number {
					position: absolute;
					inset-block-start: 0;
					inset-inline-end: 0;
					font-size: 0.625rem;
					font-weight: 500;
					background-image: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
					width: 0.875rem;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					aspect-ratio: 1;
					border-radius: 100%;
					transform: translateY(-50%) translateX(50%);
				}
			}
		}
	}
	&-profile {
		&-actions {
			margin-block-start: 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-block-end: 1rem;
			border-bottom: 2px solid #ededed;
		}
		&-balance {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			cursor: pointer;
			&-text {
				font-size: 1rem;
				font-weight: 500;
				color: #666666;
			}
			&-number {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				font-size: 1.25rem;
				font-weight: 600;
			}
		}
		&-topup {
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 0.5rem;
			color: #f7a821;
			text-transform: capitalize;
			font-size: 1.25rem;
			font-weight: 500;
		}
	}
	&-categories {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-header {
			font-size: 1.25rem;
		}
		&-list {
			display: flex;
			flex-direction: column;
			list-style: none;
			padding: 0;
			gap: 0.75rem;
			color: #808080;
			&-item {
				cursor: pointer;
			}
		}
		&-action {
			all: unset;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			text-transform: capitalize;
			align-self: start;
			cursor: pointer;
			img {
				rotate: 180deg;
				&:lang(ar) {
					rotate: 0deg;
				}
			}
		}
	}
}

.logout {
	all: unset;
	display: flex;
	align-items: center;
	gap: 0.75rem;
	font-size: 1.25rem;
	text-transform: capitalize;
	align-self: start;
	cursor: pointer;
	&-container {
		margin-block-start: auto;
		border-block-start: 2px solid #ededed;
		padding-block: 1rem;
	}
}
