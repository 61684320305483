.button {
	width: 100%;
	all: unset;
	width: 100%;
	background-color: #f7a920;
	color: #fff;
	border-radius: 0.5rem;
	padding-block: 1rem;
	text-align: center;
	position: relative;
	padding-inline: 1rem;
	cursor: pointer;
	margin-top: auto;
	box-sizing: border-box;
	font-size: 1.375rem;
	font-weight: 500;
	&:disabled {
		opacity: 0.25;
	}
	&-gradient {
		all: unset;
		cursor: pointer;
		width: 100%;
		text-align: center;
		padding-block: 1rem;
		border-radius: 0.5rem;
		box-sizing: border-box;
		color: #fff;
		padding-inline: 1rem;
		font-size: 1.375rem;
		font-weight: 500;
		background-image: linear-gradient(to right, #f5a71f 18.12%, #dc1586 93.88%);
		&:disabled {
			opacity: 0.25;
		}
	}
	&-outline {
		width: 100%;
		all: unset;
		width: 100%;
		border: 1px solid #f7a920;
		color: #f7a920;
		background-color: #fff;
		box-sizing: border-box;
		border-radius: 0.5rem;
		font-size: 1.375rem;
		font-weight: 500;
		padding-block: 1rem;
		padding-inline: 1rem;
		text-align: center;
		cursor: pointer;
		margin-top: auto;
		&:disabled {
			opacity: 0.25;
		}
	}
	&-warning {
		all: unset;
		cursor: pointer;
		width: 100%;
		text-align: center;
		padding-block: 1rem;
		border-radius: 0.5rem;
		box-sizing: border-box;
		color: #fff;
		padding-inline: 1rem;
		font-size: 1.375rem;
		font-weight: 500;
		background-color: #fb5b5b;
		&:disabled {
			opacity: 0.25;
		}
	}
}
