.container {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	.form-error {
		color: #fb5b5b;
		font-size: 0.75rem;
		padding-inline-start: 1rem;
	}
	.input-container {
		background: #f2f2f2;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 7rem;
		opacity: 1;
		border-radius: 0.5rem;
		position: relative;
		&:focus,
		&:focus-visible,
		&:focus-within {
			border: 1px solid #f7a920;
			textarea::placeholder {
				text-align: start;
				color: #bfbfbf;
			}

			textarea:-ms-input-placeholder {
				text-align: start;
				color: #bfbfbf;
			}

			textarea::-webkit-input-placeholder {
				text-align: start;
				color: #bfbfbf;
			}
			textarea {
				text-align: start;
			}
		}
		&-error {
			border: 1px solid #fb5b5b;
			&:focus,
			&:focus-visible,
			&:focus-within {
				border: 1px solid #fb5b5b;
			}
		}
		.eye {
			cursor: pointer;
			position: absolute;
			inset-inline-end: 1rem;
		}
		textarea {
			background: transparent;
			height: 100%;
			width: 100%;
			border: none;
			padding-inline: 1rem;
			padding-block: 1rem;
			resize: none;
			font-size: 0.875rem;
			box-sizing: border-box;
			color: inherit;
		}

		textarea:focus {
			outline: none;
		}

		textarea::placeholder {
			font-size: 0.875rem;
			color: #bababa;
			opacity: 1;
		}

		textarea:-ms-input-placeholder {
			color: #bababa;
		}

		textarea::-ms-input-placeholder {
			color: #bababa;
		}

		textarea[name='cvc'] {
			padding-inline-end: 2rem;
		}
		&-reverse {
			& textarea {
				text-align: start;
				padding-inline-start: 1rem;
			}
		}
		label {
			background: transparent;
			font-size: 0.875rem;
			padding-inline-start: 1rem;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			white-space: nowrap;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			margin: 0.5rem 0;
		}
	}
}
