.ticket {
	border-radius: 0.5rem;
	height: 4rem;
	width: 8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	transform-style: preserve-3d;
	cursor: pointer;
	&-front {
		width: 100%;
		height: 100%;
		position: absolute;
		inset: 0;
		backface-visibility: hidden;
	}
	&-back {
		inset: 0;
		position: absolute;
		border-radius: 0.5rem;
		transform: rotateY(180deg);
		backface-visibility: hidden;
		&-image {
			position: absolute;
			inset: 0;
			object-fit: contain;
			width: 100%;
		}
	}
	&-border {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: contain;
		inset: 0;
	}
	&-header {
		width: 100%;
		height: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f2f2f2;
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
		&-logo {
			height: 0.75rem;
		}
	}
	&-number {
		width: 100%;
		height: calc(100% - 1.5rem);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2rem;
		font-weight: 500;
		color: #f7a920;
	}
}
