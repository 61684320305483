.snackbar {
	position: fixed;
	inset-block-end: 1rem;
	inset-inline-end: -100%;
	background-image: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	border-radius: 0.5rem;
	padding: 1rem 1rem 1rem 2rem;
	transition: all 350ms ease-in-out;
	display: flex;
	align-items: center;
	gap: 1rem;
	z-index: 1000;
	&:lang(ar) {
		padding: 1rem 2rem 1rem 1rem;
	}
	&-show {
		inset-inline-end: 1rem;
	}
	&-error {
		background-image: none !important;
		background-color: #fb5b5b !important;
	}
	&-close {
		all: unset;
		cursor: pointer;
		width: 1rem;
		height: 1rem;
		&-icon {
			width: 1rem;
			height: 1rem;
		}
	}
	&-text {
		color: #fff;
		font-size: 1rem;
	}
}
