.dropdown {
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	border: 1px solid #bababa;
	&-open {
		.dropdown-header {
			border-block-end: 1px solid #efefef;
		}
		&-arrow {
			transform-origin: center center;
			transition: all 350ms ease-in-out;
			margin-inline-start: auto;
		}
	}
	&-header {
		padding: 0.75rem 1rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}
	&-icon {
	}
	&-label {
		text-transform: capitalize;
	}
	&-arrow {
		margin-inline-start: auto;
		transform-origin: center center;
		transform: rotate(-90deg);
		transition: all 350ms ease-in-out;
		&:lang(ar) {
			transform: rotate(90deg);
		}
	}
	&-content {
		&-list {
			list-style: none;
			padding: 0;
			padding: 0.5rem 1.5rem;
			display: flex;
			flex-direction: column;
			gap: 0.25rem;
			max-height: 11rem;
			overflow: scroll;
			label {
				div {
					padding-inline: 0 !important;
				}
				&::after {
					inset-inline-start: auto !important;
					inset-inline-end: 0 !important;
				}
			}
		}
	}
}
