.settings {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	&-header {
		font-weight: 500;
		font-size: 5.75rem;
		background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		text-align: center;
		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
		}
	}
	&-dropdown {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	&-hint {
		font-size: 1rem;
		font-weight: 400;
		color: #808080;
		text-align: center;
	}
	&-text {
		font-size: 1.25rem;
		font-weight: 500;
		color: #808080;
		text-transform: capitalize;
		@media screen and (max-width: 1200px) {
			margin-block-start: 2rem;
		}
	}
	&-custom {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-input {
			border: 1px solid #bfbfbf;
			height: 3rem;
			font-size: 1.25rem;
			border-radius: 1rem;
			padding-inline: 1rem;
			text-align: center;
			&::placeholder {
				text-align: center;
			}
			&:focus,
			&:focus-visible,
			&:focus-within {
				outline: none;
			}
		}
	}
	&-pay {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		&-text {
			font-size: 1.25rem;
			font-weight: 500;
			color: #808080;
			text-transform: capitalize;
		}
	}
	&-amount {
		display: flex;
		flex-wrap: wrap;
		gap: 4.25rem;
		padding-inline: 4rem;
		&-button {
			all: unset;
			cursor: pointer;
			border: 1px solid #bfbfbf;
			font-size: 2rem;
			font-weight: 700;
			color: #808080;
			padding-block: 1rem;
			text-align: center;
			width: calc(calc(100% / 3) - calc(4.5rem * 2 / 3));
			border-radius: 1.25rem;
			&-selected {
				border-color: #dd1a82;
				color: #dd1a82;
			}
		}
	}
	&-delete {
		border-radius: 1rem;
		border: 1px solid #bababa;
		padding: 0.75rem 1rem;
		background: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-size: 1rem;
		text-transform: capitalize;
	}
}

.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-header {
		display: flex;
		color: #000000;
		font-weight: 500;
		font-size: 1.5rem;
		justify-content: center;
		align-items: center;
	}
	&-close {
		width: 1.5rem;
		aspect-ratio: 1;
		position: absolute;
		inset-block-start: 1.5rem;
		inset-inline-end: 1rem;
		transform: translateY(-50%);
		cursor: pointer;
	}
	&-container {
		background-color: #fff;
		border-radius: 1rem;
		padding: 2rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		position: relative;
		min-width: 30rem;
		@media screen and (max-width: 500px) {
			min-width: 0;
			width: 90%;
		}
		button {
			text-transform: capitalize;
			&:last-child {
				background-color: transparent;
				color: #808080;
			}
		}
	}
	&-text {
		font-size: 1.25rem;
		color: #3c3c43;
		margin-block: 1.5rem 2rem;
	}
}
