.help {
	display: flex;
	flex-direction: column;
	align-items: center;
	&-main {
		padding-block: 2rem;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	&-email {
		display: flex;
		gap: 1rem;
		align-items: flex-start;
		&-text {
			display: flex;
			flex-direction: column;
			gap: 0.25rem;
			font-size: 0.75rem;
			a {
				display: flex;
				flex-direction: column;
				gap: 0.25rem;
				font-size: 0.75rem;
			}
		}
		&-email {
			font-size: 0.875rem;
			font-weight: 500;
			color: #3c3c43;
		}
	}
	&-contact {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		&-header {
			color: #1a191a;
			text-transform: capitalize;
		}
		&-label {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			text-transform: capitalize;
			font-size: 0.875rem;
			color: #3c3c43;
		}
		button {
			margin-block-start: 3rem;
		}
	}
}
