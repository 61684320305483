.terms-of-use {
	display: flex;
	flex-direction: column;
	align-items: center;
	&-header {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: 700;
		font-size: 2rem;
		text-align: center;
		padding-block: 3rem;
		background: linear-gradient(135deg, #f7a821 0%, #dd1a82 100%);
	}
	&-main {
		width: 90%;
		margin-block-start: -2.5rem;
		padding-inline: var(--padding-x);
		background-color: #fff;
		padding: 3rem;
		padding-block-end: 7rem;
		border-start-start-radius: 1rem;
		border-start-end-radius: 1rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		&-section {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			line-height: 1.5;
			&-title {
				font-weight: 500;
				font-size: 1.25rem;
			}
			&-text {
				color: #808080;
				font-size: 0.875rem;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				& > div {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
				}
				h2 {
					font-size: 1.125rem;
					color: #000;
				}
				h3 {
					font-size: 1rem;
					color: #000;
				}
				ul {
					padding: 1rem;
					font-size: 0.875rem;
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
				}
				a {
					color: #dd1a82;
				}
			}
		}
	}
}
