.live-wheel {
	position: fixed;
	inset: 0;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	animation: changeBackgroundColor 10s forwards;
	&-text {
		font-size: 4rem;
		font-weight: 500;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		text-align: center;
		@media screen and (max-width: 500px) {
			font-size: 3.5rem;
		}
		&-in {
			font-size: 3rem;
			@media screen and (max-width: 500px) {
				font-size: 3rem;
			}
		}
	}
	&-number {
		font-size: 14rem;
		font-weight: 500;
		@media screen and (max-width: 500px) {
			font-size: 12rem;
		}
	}
}

@keyframes changeBackgroundColor {
	0% {
		background-color: #dc1284;
	}
	10% {
		background-color: #a51d5d;
	}
	20% {
		background-color: #f49c26;
	}
	30% {
		background-color: #b46926;
	}
	40% {
		background-color: #ed7443;
	}
	50% {
		background-color: #b25237;
	}
	60% {
		background-color: #e95c54;
	}
	70% {
		background-color: #ab324a;
	}
	80% {
		background-color: #e13a6c;
	}
	90% {
		background-color: #dc1284;
	}
	100% {
		background-color: #a51d5d;
	}
}
