.default-slider,
.active-wheels-slider {
  .prev-button {
    top: 50%;
    transform: translateY(55%);
    position: absolute;
    rotate: 180deg;
    cursor: pointer;
    inset-inline-start: 0;
    z-index: 10;
    color: #f7a920;
  }
  .prev-button:lang(ar) {
    rotate: 0deg;
    transform: translateY(-50%);
  }
  .swiper-slide {
  width: auto !important;
}
  .next-button {
    top: 50%;
    transform: translateY(-45%);
    inset-inline-end: 0;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    color: #f7a920;
  }
  .next-button:lang(ar) {
    transform: translateY(50%);
    rotate: 180deg;
  }
  .swiper-button-disabled {
    color: #e4e4e4;
  }
}

.swiper {
  position: unset !important;
  z-index: 0;
}

.swiper-pagination {
  width: auto !important;
  height: 1rem !important;
  inset-inline-start: 50% !important;
  inset-block-end: 0 !important;
  transform: translateX(-50%) !important;
}
.swiper-pagination-bullet {
  width: 0.625rem !important;
  height: 0.625rem !important;
}
.swiper-pagination-bullet-active {
  background-color: #f7a920 !important;
}

.swiper-button-disabled {
  color: #e4e4e4;
  cursor: default;
}

.vertical-slider {
  max-height: 100%;
  height: 100% !important;
  position: relative !important;
  .swiper-slide {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.active-wheels-slider {
  .next-button {
    inset-inline-end: 1rem;
  }
  .prev-button {
    inset-inline-start: 1rem;
  }
}

.auto-swiper {
  width: 100% !important;
  position: relative !important;
  .swiper-slide {
    width: auto !important;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 8rem;
    height: 4rem;
  }
}
.non-auto-swiper {
  .swiper-wrapper {
    display: flex !important;
    justify-content: center !important;
    gap: 1rem !important;
  }
  .swiper-slide {
    width: auto !important;
  }
}
.ads-slider {
  height: auto;
  border-radius: 2rem !important;
  .swiper-wrapper {
    height: auto !important;
  }
  .swiper-slide {
    border-radius: 2rem !important;
    width: 100% !important;
    img {
      border-radius: 2rem;
    }
  }
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    display: flex;
    gap: 0.25rem;
    inset-block-end: 3.325rem !important;
  }
  .swiper-pagination:lang(ar) {
    transform: translateX(50%) !important;
  }
  .swiper-pagination-bullet {
    display: block;
    border-radius: 100%;
    width: 0.5rem !important;
    height: 0.5rem !important;
    background-color: #fff;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background-color: #fff !important;
    width: 2rem !important;
    border-radius: 1rem;
  }
  .prev-button,
  .next-button {
    width: 3.25rem;
    height: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #fff;
    inset-block-start: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }
  .prev-button {
    transform: translateY(-50%) translateX(-50%);
    inset-inline-start: 0;
  }
  .prev-button:lang(ar) {
      transform: translateY(50%) translateX(-50%);
      inset-inline-start: 0;
      rotate: 180deg;
  }
  .next-button {
    transform: translateY(-50%) translateX(50%);
    inset-inline-end: 0;
  }
  .next-button:lang(ar) {
    transform: translateY(50%) translateX(50%);
    inset-inline-end: 0;
    rotate: 180deg;
  }
}

.big-win-slider {
  height: auto;
  .swiper-wrapper {
    height: auto !important;
  }
  .swiper-slide {
    border-radius: 2rem;
    img {
      border-radius: 2rem;
    }
  }
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    display: flex;
    gap: 0.25rem;
    inset-block-end: -2.5rem !important;
  }
  .swiper-pagination-bullet {
    display: block;
    border-radius: 100%;
    width: 0.5rem !important;
    height: 0.5rem !important;
    cursor: pointer;
    background-color: #D9D9D9;
  }
  .swiper-pagination-bullet-active {
    width: 1.25rem !important;
    border-radius: 1rem;
    background: linear-gradient(180deg, #F59B2A 0%, #E02978 100%) !important;
  }
  .prev-button,
  .next-button {
    width: 3.25rem;
    height: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    inset-block-end: -5rem;
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }
  .prev-button {
    transform: translateY(-50%) translateX(-175%);
    inset-inline-start: 50%;
  }
  .next-button {
    transform: translateY(-50%) translateX(175%);
    inset-inline-end: 50%;
  }
}

@media screen and (max-width: 769px) {
  .ads-slider {
    .swiper-pagination {
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: center;
      gap: 0.25rem;
      inset-block-end: 0 !important;
      margin-top: 0.5rem;
    }
    .swiper-pagination-bullet {
      display: block;
      border-radius: 100%;
      width: 0.5rem !important;
      height: 0.5rem !important;
      background-color: #D9D9D9;
      cursor: pointer;
    }
    .swiper-pagination-bullet-active {
      background-color: #F7A920 !important;
      width: 2rem !important;
      border-radius: 1rem;
    }
    .prev-button,
    .next-button {
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: #fff;
      inset-block-start: 50%;
      position: absolute;
      cursor: pointer;
      z-index: 10;
      img {
        display: none;
      }
    }
    .prev-button {
      transform: translateY(calc(-50% - 0.75rem)) translateX(-50%);
      inset-inline-start: 0;
    }
    .next-button {
      transform: translateY(calc(-50% - 0.75rem)) translateX(50%);
      inset-inline-end: 0;
    }
  }
}