.notifications {
	position: fixed;
	inset-inline-end: 0;
	inset-block: 0;
	inset-inline-start: 0;
	background-color: #0003;
	z-index: 1000;
	backdrop-filter: blur(2px);
	transform: translateX(100%);
	cursor: pointer;
	&-close {
		position: absolute;
		inset-inline-end: 1rem;
		inset-block-start: 1rem;
		width: 1.5rem;
		aspect-ratio: 1;
		object-fit: contain;
		cursor: pointer;
	}
	&-open {
		transform: translateX(0);
	}
	&-empty-container {
		position: relative;
		width: 40%;
		height: 100%;
		background-color: #fff;
		margin-inline-start: auto;
		cursor: default;
		display: grid;
		place-content: center;
		&-image {
			width: 10rem;
		}
		@media screen and (max-width: 769px) {
			width: 50%;
		}
		@media screen and (max-width: 500px) {
			width: 80%;
		}
	}
	&-container {
		position: relative;
		width: 40%;
		height: 100%;
		background-color: #fff;
		margin-inline-start: auto;
		cursor: default;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-block-start: 2rem;
		min-width: 25rem;
		&-header {
			font-size: 1.5rem;
			align-self: center;
			background: linear-gradient(to bottom, #f7a821 0%, #dd1a82 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
			text-align: center;
		}
		@media screen and (max-width: 769px) {
			width: 50%;
		}
		@media screen and (max-width: 500px) {
			width: 80%;
		}
	}
	&-list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding: 1rem;
		overflow: scroll;
		background-color: #f9f9f9;
	}
}
