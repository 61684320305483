.live-wheel {
	height: 100vh;
	display: flex;
	align-items: stretch;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
	&-logo {
		@media screen and (max-width: 500px) {
			background-image: linear-gradient(174.76deg, #ffd16a 3.68%, #f5a71f 18.11%, #dc1586 93.87%);
			width: 100%;
			padding-block: 1rem;
			padding-inline: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&-details {
		width: 25%;
		min-width: 30rem;
		background-image: linear-gradient(174.76deg, #ffd16a 3.68%, #f5a71f 18.11%, #dc1586 93.87%);
		padding-inline: 2.5rem;
		padding-block: 2rem 3.625rem;
		border-start-end-radius: 0.5rem;
		border-end-end-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media screen and (max-width: 1200px) {
			padding-inline: 1rem;
			min-width: 20rem;
		}
		@media screen and (max-width: 768px) {
			flex-direction: row;
			width: 100%;
			align-items: flex-start;
			gap: 1rem;
			justify-content: space-between;
		}
		@media screen and (max-width: 500px) {
			background-image: none;
			background-color: #fff;
			flex-direction: column;
			padding: 0;
		}

		&-logo {
			width: 50%;
			max-width: 10.5rem;
		}
		&-content {
			width: 100%;
			background-color: #fff;
			border-radius: 1.5rem;
			@media screen and (max-width: 768px) {
				width: 60%;
				min-width: 20rem;
			}
			@media screen and (max-width: 600px) {
				width: 100%;
			}
			&-section {
				padding-inline: 2rem;
				padding-block: 1.5rem;
				border-top: 2px solid #f7f7f7;
				&:nth-of-type(1) {
					border-top: none;
				}
				@media screen and (max-width: 1200px) {
					padding-inline: 1.5rem;
					padding-block: 0.5rem;
				}
				@media screen and (max-width: 500px) {
					padding-block: 0;
					border: 0;
				}
			}
			&-title {
				font-size: 3rem;
				font-weight: 700;
				color: #f7a920;
				display: flex;
				align-items: center;
				justify-content: space-between;
				&-sec {
					font-weight: 400;
					color: #fff;
					font-size: 0.875rem;
					background: radial-gradient(46.62% 48.85% at 49.85% 51.31%, #e23373 50%, #f7ac38 100%);
					padding: 0.25rem 0.75rem;
					align-self: flex-start;
					border-radius: 3rem;
					width: fit-content;
					display: none;
					text-transform: uppercase;
					span {
						font-weight: 700;
					}
					@media screen and (max-width: 768px) {
						display: block;
					}
				}
				@media screen and (max-width: 1200px) {
					font-size: 2.5rem;
				}
			}
			&-text {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				font-size: 1rem;
				font-weight: 500;
				color: #808080;
				text-transform: uppercase;
			}
		}
		&-product {
			display: flex;
			align-items: center;
			gap: 2rem;
			&-content {
				width: 70%;
				display: flex;
				flex-direction: column;
				gap: 0.25rem;
			}
			&-title {
				font-weight: 500;
				font-size: 0.75rem;
				color: #808080;
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
			&-name {
				font-weight: 500;
				font-size: 1.125rem;
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
			&-wheel {
				margin-top: 0.75rem;
				font-size: 0.875rem;
				display: flex;
				align-items: center;
				gap: 0.5rem;
				font-weight: 500;
				color: #808080;
				text-transform: uppercase;
				letter-spacing: 1px;
				span {
					font-weight: 500;
					font-size: 0.875rem;
					color: #808080;
					padding: 0.125rem 0.75rem;
					background-color: #d9d9d9;
					border-radius: 1rem;
				}
			}
			&-image {
				width: 30%;
			}
		}
		&-tickets {
			&-title {
				font-weight: 700;
				color: #808080;
				text-transform: capitalize;
			}
		}
	}
}

.wheel {
	width: 75%;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		width: 100%;
		// gap: 2rem;
		flex-grow: 1;
	}
	&-title {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;
		background-color: #f2f2f2;
		padding-block: 2rem;
		font-size: 1.25rem;
		font-weight: 500;
		text-transform: capitalize;
		&-sec {
			position: absolute;
			inset-inline-end: 4rem;
			font-weight: 400;
			color: #fff;
			font-size: 1rem;
			background: radial-gradient(46.62% 48.85% at 49.85% 51.31%, #e23373 50%, #f7ac38 100%);
			padding: 0.5rem 1.5rem;
			align-self: flex-start;
			border-radius: 3rem;
			width: fit-content;
			text-transform: uppercase;
			span {
				font-weight: 700;
			}
			@media screen and (max-width: 1300px) {
				inset-inline-end: 1rem;
				inset-block-start: 2rem;
			}
			@media screen and (max-width: 1000px) {
				padding: 0.25rem 0.75rem;
				font-size: 0.875rem;
				inset-block-start: 1rem;
			}
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		@media screen and (max-width: 768px) {
			padding-block: 1rem;
		}
		&-slots {
			font-size: 3rem;
			font-weight: 700;
			background: linear-gradient(to top, #f7a821 0%, #dd1a82 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
	}
	&-wheel {
		z-index: 0;
		position: relative;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url(../../assets/images/wheelBg.png);
		background-position: center;
		@media screen and (max-width: 768px) {
			// max-height: 27.5rem;
			overflow: hidden;
			align-items: flex-start;
			aspect-ratio: 1/0.7;
			padding-block-start: 2rem;
			flex-grow: 1;
		}
	}
}

.live-dot {
	display: block;
	width: 0.5rem;
	height: 0.5rem;
	background-color: #08a045;
	border-radius: 100%;
	animation: shadow 1s infinite alternate-reverse;
}

.tickets-container {
	z-index: -1;
	display: flex;
	padding-block: 0.5rem;
	overflow-x: hidden;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	&-small {
		display: flex;
		gap: 0.25rem;
	}
}

@keyframes shadow {
	0% {
		scale: 1;
	}
	100% {
		scale: 1.5;
	}
}
