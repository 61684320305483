.hottest-wheels {
	width: 100%;
	display: flex;
	flex-direction: column;
	&-header {
		font-weight: 600;
		font-size: 2.5rem;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		gap: 0.25rem;
		align-self: start;
		padding-block: 0.5rem;
		padding-inline: 0.5rem;
		color: #000;
	}
	&-slider {
		width: 100%;
		background-image: radial-gradient(circle, #dd1a82, #dd1a82 60%, #f7a821);
	}
	&-products {
		// overflow-x: auto;
		width: 100%;
		// display: flex;
		// flex-wrap: wrap;
		gap: 1rem;
		padding-block: 3rem;
		padding-inline: 3rem;
		border-radius: 2rem;
		background-image: radial-gradient(circle, #dd1a82, #dd1a82 60%, #f7a821);
		@media screen and (max-width: 1024px) {
			// flex-wrap: nowrap;
			// overflow: scroll;
			// scrollbar-width: none;
			// padding-inline: 0.5rem;
		}
	}
	&-product {
		width: calc(calc(100% / 4) - calc(1rem * 3 / 4));
		@media screen and (max-width: 1024px) {
			width: calc(calc(100% / 3) - calc(1rem * 2 / 3));
		}
		@media screen and (max-width: 769px) {
			width: calc(calc(100% / 2) - calc(1rem / 2));
		}
		@media screen and (max-width: 450px) {
			width: 100%;
		}
	}
}
